import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, type PropType, ref } from 'vue';
import { isRtl } from '@/plugins/i18n/helpers';
import { getLastZIndex } from '@shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-popover',
  props: {
  hold: { type: [Boolean, Number] as PropType<number | boolean>, default: false },
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
  closeTimeout: { type: Number, default: 0 },
},
  emits: ['click', 'dblclick'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const isActive = ref<boolean>(false);
const zIndex = ref<number>(getLastZIndex() + 1);
const popoverRef = ref<HTMLDivElement>();
const popoverContent = ref<HTMLDivElement>();
const loadingIndex = ref<number | null>(null);
const pressTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const closeTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const ignoreNextClick = ref(false);
const lastTap = ref(0);

const popoverStyle = computed(() => {
  if (!popoverRef.value || !popoverContent.value) {
    return {};
  }

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const { offsetLeft: mainLeft, offsetTop: mainTop, clientHeight: mainHeight, clientWidth: mainWidth } = popoverRef.value;
  const { clientWidth: contentWidth } = popoverContent.value;

  const isLtr = !isRtl();
  const positionX = isLtr ? mainLeft + +props.offsetX : mainLeft + (mainWidth - contentWidth) - +props.offsetX;

  const targetTop = mainTop + mainHeight + 4 + +props.offsetY;
  const targetLeft = contentWidth + positionX < windowWidth ? Math.max(positionX, 4) + 'px' : 'initial';
  const targetRight = contentWidth + positionX > windowWidth ? Math.min(windowWidth - positionX - mainWidth, windowWidth) + 'px' : 'initial';

  return {
    top: targetTop + 'px',
    left: targetLeft,
    right: targetRight,
    maxHeight: windowHeight - targetTop - 16 + 'px',
    zIndex: zIndex.value,
  };
});

const onClose = (ev?: Event) => {
  if (ignoreNextClick.value && ev) {
    ignoreNextClick.value = false;

    return;
  }

  if (ev && (popoverRef.value?.contains(ev?.target as Node) || popoverContent.value?.contains(ev?.target as Node))) {
    return;
  }

  isActive.value = false;
  window.removeEventListener('click', onClose);
  window.removeEventListener('touchstart', onClose);
};

const onOpen = (ev?: Event): void => {
  if ((props.hold && ev) || props.disabled) {
    emit('click');

    return;
  }

  ev?.preventDefault();

  isActive.value = true;
  loadingIndex.value = null;
  zIndex.value = getLastZIndex() + 1;

  window.addEventListener('click', onClose);
  window.addEventListener('touchstart', onClose);

  if (props.closeTimeout) {
    clearTimeout(closeTimer.value);
    closeTimer.value = setTimeout(() => onClose(), props.closeTimeout);
  }
};

const onPressStart = (ev: Event) => {
  emit('click');

  const currentTime = new Date().getTime();
  const tapLength = currentTime - lastTap.value;

  if (tapLength < 300 && tapLength > 0) {
    emit('dblclick');
  }

  lastTap.value = currentTime;

  if (props.hold) {
    ev.preventDefault();
  }

  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }

  pressTimer.value = setTimeout(onOpen, typeof props.hold === 'number' ? props.hold : 500);
};

const onPressEnd = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

const onPressCancel = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

__expose({ close: onClose, open: onOpen });

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-popover', { 'vz-popover--background': isActive.value && __props.dim }])
  }, [
    _createElementVNode("div", {
      ref_key: "popoverRef",
      ref: popoverRef,
      class: _normalizeClass(['vz-popover__activator', { 'vz-popover__activator--disabled': __props.disabled }]),
      role: "button",
      onMousedown: onPressStart,
      onMouseup: onPressEnd,
      onMouseleave: onPressCancel,
      onTouchstart: onPressStart,
      onTouchend: onPressEnd,
      onTouchcancel: onPressCancel,
      onDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dblclick'))),
      onClick: onOpen
    }, [
      _renderSlot(_ctx.$slots, "activator", {}, () => [
        _createVNode(_component_vz_icon, {
          name: "svg:menu",
          size: "1.5rem",
          color: isActive.value ? 'primary-700' : 'primary-900'
        }, null, 8, ["color"])
      ])
    ], 34),
    (isActive.value && !__props.disabled && (_ctx.$slots['top'] || _ctx.$slots['default'] || _ctx.$slots['bottom']))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref_key: "popoverContent",
          ref: popoverContent,
          class: "vz-popover__content",
          style: _normalizeStyle(popoverStyle.value)
        }, [
          _renderSlot(_ctx.$slots, "default", { close: onClose })
        ], 4))
      : _createCommentVNode("", true)
  ], 2))
}
}

})