import { DirectiveBinding } from 'vue';

type Element = HTMLElement & { _scrollHandler?: () => void; _lastHeight?: number };

const scrollToView = (el: Element, behavior: ScrollBehavior = 'smooth') => {
  setTimeout(() => {
    el._lastHeight = el.clientHeight;
    el.scrollIntoView({ behavior, block: 'nearest' });
  });
};

export const vueScrollToView = {
  mounted(el: Element) {
    el._lastHeight = el.clientHeight;
  },
  updated(el: Element, binding: DirectiveBinding<[boolean?, ScrollBehavior?]>) {
    const [isActive, behavior] = binding.value || [];

    if (isActive && el._lastHeight && el.clientHeight !== el._lastHeight) {
      scrollToView(el, behavior);
    }
  },
  unmounted(el: Element) {
    delete el._lastHeight;
  },
};
