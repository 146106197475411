import type { RouteLocation } from 'vue-router';
import router from '@/router';
import { isEqual } from 'lodash';

export const routeTo = async (
  route: Partial<Pick<RouteLocation, 'name' | 'path' | 'params' | 'query'>> | string,
  config?: Partial<{ isOverride: boolean; skipCache: boolean; isRedirectFrom: boolean }>
): Promise<void> => {
  const { isOverride = false, skipCache = false, isRedirectFrom = false } = config || {};
  const { name = undefined, path = undefined, query = undefined, params = undefined } = typeof route === 'string' ? { name: route } : route;
  const { name: currentName, path: currentPath, query: currentQuery, params: currentParams } = router.currentRoute.value;
  const hasProperty = (obj: Record<any, any> = {}, key: string): boolean => Object.prototype.hasOwnProperty.call(obj, key);
  const hasRouteNameChanged = currentName !== name;
  const hasRoutePathChanged = currentPath !== path;
  const hasRouteQueryChanged = !isEqual(currentQuery, query);
  const hasRouteParamsChanged = !isEqual(currentParams, params);

  if (hasRouteNameChanged || hasRoutePathChanged || hasRouteQueryChanged || hasRouteParamsChanged) {
    const routeParams = Object.entries(isOverride ? params || {} : { ...(currentParams || {}), ...(params || {}) }).reduce((acc, [key, value]) => {
      const newValue = isOverride ? (hasProperty(params, key) ? value : null) : value;

      return newValue === null ? acc : { ...acc, [key]: newValue };
    }, {});

    const initQuery = isRedirectFrom ? { from: currentName, ...(currentQuery || {}) } : {};
    const routeQuery = Object.entries(isOverride ? query || {} : { ...(currentQuery || {}), ...(query || {}) }).reduce((acc, [key, value]) => {
      const newValue = isOverride ? (hasProperty(query, key) ? value : null) : value;

      return newValue === null ? acc : { ...acc, [key]: newValue };
    }, initQuery);

    const routeName = name || currentName;

    const route = {
      ...(path ? { path } : { name: routeName }),
      ...(Object.entries(routeParams).length ? { params: routeParams } : null),
      ...(Object.entries(routeQuery).length ? { query: routeQuery } : null),
    } as RouteLocation;

    await (skipCache ? router.replace(route) : router.push(route));
  }
};
