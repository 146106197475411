import type { CSSProperties, DirectiveBinding } from 'vue';

type Element = HTMLElement & { createTooltip: (event: MouseEvent) => void; removeTooltip: () => void };
type TooltipProps = { text: string; color: string; isAlwaysOn: boolean; style?: CSSProperties };
type TooltipBinding = DirectiveBinding<null | string | boolean | TooltipProps>;

const supportsHover = () => window.matchMedia('(hover: hover)').matches;

const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function (...args: any[]) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      func(...args);
    }, wait);
  };
};

const getFormattedText = (element: HTMLElement): string => {
  let text = '';

  const traverse = (node: Node): void => {
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.nodeValue?.trim() || '';
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const computedStyle = window.getComputedStyle(node as HTMLElement);
      const isBlock = computedStyle.display === 'block';

      if (isBlock && text.trim()) {
        text += '\n';
      } else {
        text += ' ';
      }

      node.childNodes.forEach(traverse);

      if (isBlock) {
        text += '\n';
      }
    }
  };

  traverse(element);

  return text.trim().replace(/\n\s*\n/g, '\n');
};

const applyTooltipStyle = (el: HTMLElement): void => {
  el.style.cursor = 'default';
};

const setTooltipProps = (event: MouseEvent, tooltipEl: HTMLElement, color: string, text?: string, style?: CSSProperties): void => {
  const targetEl = event.target as HTMLElement;

  // Set tooltip content
  tooltipEl.innerHTML = text || getFormattedText(targetEl);

  // Get bounding rectangles for positioning
  const targetRect = targetEl.getBoundingClientRect();
  const tooltipRect = tooltipEl.getBoundingClientRect();

  // Calculate available space
  const spaceAbove = targetRect.top;
  const spaceBelow = window.innerHeight - targetRect.bottom;
  const spaceLeft = targetRect.left;
  const spaceRight = window.innerWidth - targetRect.right;

  let top: number;
  let left: number;

  // Prioritize positioning: Bottom > Top > Left > Right
  if (spaceBelow >= tooltipRect.height) {
    // Position below and align tooltip's center anchor with target's center anchor
    top = targetRect.bottom + 10;
    left = Math.max(Math.min(targetRect.left + targetRect.width / 2 - tooltipRect.width / 2, window.innerWidth - tooltipRect.width - 10), 10);
  } else if (spaceAbove >= tooltipRect.height) {
    // Position above and align tooltip's center anchor with target's center anchor
    top = targetRect.top - tooltipRect.height - 10;
    left = Math.max(Math.min(targetRect.left + targetRect.width / 2 - tooltipRect.width / 2, window.innerWidth - tooltipRect.width - 10), 10);
  } else if (spaceLeft >= tooltipRect.width) {
    // Position to the left and center vertically
    top = Math.max(Math.min(targetRect.top + targetRect.height / 2 - tooltipRect.height / 2, window.innerHeight - tooltipRect.height - 10), 10);
    left = targetRect.left - tooltipRect.width - 10;
  } else if (spaceRight >= tooltipRect.width) {
    // Position to the right and center vertically
    top = Math.max(Math.min(targetRect.top + targetRect.height / 2 - tooltipRect.height / 2, window.innerHeight - tooltipRect.height - 10), 10);
    left = targetRect.right + 10;
  } else {
    // Default: Adjust to fit within viewport (fallback)
    top = Math.min(Math.max(targetRect.bottom + 10, 10), window.innerHeight - tooltipRect.height - 10);
    left = Math.min(Math.max(targetRect.left + targetRect.width / 2 - tooltipRect.width / 2, 10), window.innerWidth - tooltipRect.width - 10);
  }

  // Apply styles
  tooltipEl.style.top = `${top}px`;
  tooltipEl.style.left = `${left}px`;
  tooltipEl.style.position = 'fixed';
  tooltipEl.style.zIndex = '1000000000';
  tooltipEl.style.backgroundColor = 'white';
  tooltipEl.style.boxShadow = '0 0 10px 0 rgba(186, 199, 220, 0.71)';
  tooltipEl.style.padding = '10px 15px';
  tooltipEl.style.borderRadius = '8px';
  tooltipEl.style.color = color;
  tooltipEl.style.whiteSpace = 'pre-wrap';
  tooltipEl.style.maxWidth = '300px';
  tooltipEl.style.wordWrap = 'break-word';
  tooltipEl.setAttribute('tooltip', tooltipEl.innerText);

  Object.entries(style || {}).forEach(([key, value]) => {
    tooltipEl.style[key as any] = value;
  });
};

export const vueTooltip = {
  mounted: (el: Element, binding: TooltipBinding) => {
    if (binding.value === null) {
      return;
    }

    const { text, color, isAlwaysOn, style } = (() => {
      if (typeof binding.value === 'string') {
        return { text: binding.value, color: 'var(--color-primary-900)', isAlwaysOn: false, style: {} };
      } else if (typeof binding.value === 'boolean') {
        return { text: undefined, color: 'var(--color-primary-900)', isAlwaysOn: binding.value, style: {} };
      }

      return { ...binding.value };
    })();

    applyTooltipStyle(el);

    if (el?.offsetWidth < el?.scrollWidth || text || isAlwaysOn) {
      const tooltipEl = document.createElement('div');
      const body = document.querySelector('body');

      el.createTooltip = (event: MouseEvent): void => {
        if (!supportsHover()) {
          return;
        }

        document.querySelectorAll('[tooltip]').forEach((el) => el.remove());

        setTooltipProps(event, tooltipEl, color, text, style);

        body?.appendChild(tooltipEl);
      };

      el.removeTooltip = debounce((): void => {
        if (tooltipEl && body?.contains(tooltipEl)) {
          body.removeChild(tooltipEl);
        }
      }, 300);

      el.addEventListener('mouseenter', el.createTooltip);
      el.addEventListener('mouseleave', el.removeTooltip);
    }
  },
  unmounted: (el: Element) => {
    el.removeTooltip?.();
    el.removeEventListener('mouseenter', el.createTooltip);
    el.removeEventListener('mouseleave', el.removeTooltip);

    delete (el as Partial<Element>).createTooltip;
    delete (el as Partial<Element>).removeTooltip;
  },
};
