import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import NotificationsGrid from '@/views/notifications/components/notifications-grid.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'notifications-tabs',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(NotificationsGrid, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16))
}
}

})