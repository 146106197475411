import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "bg-active border-radius-50 width-42 height-42 aspect-1-1 d-flex align-center justify-center"
}
const _hoisted_2 = { class: "notification-card__content ps-2 text-body-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-title-1"
}
const _hoisted_4 = {
  key: 1,
  class: "text-title-1"
}
const _hoisted_5 = { class: "c-mono-400" }
const _hoisted_6 = { key: 2 }

import type { NotificationItem } from '@/views/notifications/store/notification.types';
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type PropType } from 'vue';
import { GlobalVariables } from '@/shared/constants/global-variables';
import { routeTo } from '@shared/composables';
import { isToday } from '@/views/calendar/helpers';
import dayjs from 'dayjs';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'notification-card',
  props: {
  loading: { type: Boolean, default: false },
  clickable: { type: Boolean, default: true },
  item: { type: Object as PropType<NotificationItem>, required: true },
},
  setup(__props) {

const props = __props;

const routes = router.getRoutes();

const route = computed(() => props.item?.route);
const title = computed(() => props.item?.title);
const user = computed(() => props.item?.from);

const icon = computed(() => {
  return (routes.find(({ name }) => name === route.value?.name)?.meta?.icon as IconName) || undefined;
});

const createdAt = computed(() => {
  const createdAt = props.item?.createdAt;

  if (!createdAt) {
    return null;
  }

  return isToday(createdAt) ? dayjs(createdAt).fromNow() : dayjs(createdAt).format(GlobalVariables.FULL_DATE_AND_TIME);
});

const onClick = async (): Promise<void> => {
  const { route } = props.item;

  if (!route) {
    return;
  }

  await routeTo(route);
};

return (_ctx: any,_cache: any) => {
  const _component_vz_image = _resolveComponent("vz-image")!
  const _component_vz_avatar = _resolveComponent("vz-avatar")!
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['notification-card', 'clickable', { skeleton: __props.loading }]),
    onClick: onClick
  }, [
    (__props.item?.image)
      ? (_openBlock(), _createBlock(_component_vz_image, {
          key: 0,
          class: "border-radius-50",
          src: __props.item.image,
          width: "42",
          height: "42"
        }, null, 8, ["src"]))
      : (user.value)
        ? (_openBlock(), _createBlock(_component_vz_avatar, {
            key: 1,
            "model-value": user.value?.avatar,
            hid: user.value?._id,
            size: "42",
            verified: user.value?.isVerified
          }, null, 8, ["model-value", "hid", "verified"]))
        : (icon.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_vz_icon, {
                size: "28px",
                name: icon.value
              }, null, 8, ["name"])
            ]))
          : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (title.value && !user.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(title.value), 1))
        : (user.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(user.value.firstName) + " " + _toDisplayString(user.value.lastName), 1))
          : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_5, _toDisplayString(createdAt.value), 1),
      _createElementVNode("p", null, _toDisplayString(__props.item.type), 1),
      (title.value && user.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(title.value), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})