/* eslint-disable */
import type { RichTextToolbarOption } from '@shared/elements/rich-text/rich-text.types';
import { NONE, RICH_TEXT_TOOLBAR, RichTextElementTypes } from './rich-text.constants';
import './rich-text.css';
import { getLastZIndex } from '@shared/helpers';

class RichTextEditor extends HTMLElement {
  private readonly container: HTMLDivElement = document.createElement('div');
  private readonly editor: HTMLDivElement = document.createElement('div');
  private undoBulk: Array<string> = [];
  private redoBulk: Array<string> = [];
  private toolbar: HTMLElement;
  private dynamicMap: Record<string, string> = {};
  private shortcutMap: Record<string, () => void> = {
    z: () => this.execCommand('undo'),
    y: () => this.execCommand('redo'),
  };
  private zIndex: number = getLastZIndex() + 1;

  constructor() {
    super();

    this.container.classList.add('rich-text');
    this.editor.classList.add('rich-text__editor');

    this.initEditor();
    this.initToolbar(this.execCommand.bind(this));

    this.addEventListeners();
  }

  static get observedAttributes(): Array<string> {
    return [
      'value',
      'direction',
      'dynamic-fields',
      'insert-dynamic-field',
      ...RICH_TEXT_TOOLBAR.reduce((bulk: Array<string>, elements) => [...bulk, ...Object.keys(elements)], []),
    ];
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    const toolbarOptions = [
      'value',
      'direction',
      'dynamic-fields',
      'insert-dynamic-field',
      ...RICH_TEXT_TOOLBAR.reduce((bulk: Array<string>, elements) => [...bulk, ...Object.keys(elements)], []),
    ];

    switch (name) {
      case 'value':
        this.initEditor(newValue);
        break;
      case 'direction':
        this.setDocDirection((newValue || 'ltr') as 'ltr' | 'rtl');
        break;
      default:
        if (toolbarOptions.includes(name)) {
          this.initToolbar(this.execCommand.bind(this));
        }
    }
  }

  private createSeparatorElement() {
    const separator = document.createElement('div');
    separator.style.margin = '0 2px';

    return separator;
  }

  private createOptionElement(text: string, value?: string, selected?: boolean, disabled?: boolean) {
    const option = document.createElement('option');
    option.innerText = text;

    if (value) {
      option.setAttribute('value', value);
    }

    if (selected) {
      option.setAttribute('selected', selected.toString());
    }

    if (disabled) {
      option.setAttribute('disabled', disabled.toString());
    }

    return option;
  }

  private createSelectElement(
    actionId: RichTextToolbarOption['action'],
    title: RichTextToolbarOption['title'],
    options: RichTextToolbarOption['options'],
    execCommand: (command: string, value?: string) => void
  ) {
    const select = document.createElement('select');
    select.dataset.actionId = actionId;
    select.title = title;
    select.classList.add('rich-text__toolbar-select');
    select.appendChild(this.createOptionElement(title, '', true, true));
    select.addEventListener('change', (e) => {
      const selectedValue = (e.target as HTMLSelectElement).value;

      if (selectedValue) {
        execCommand(actionId, selectedValue);

        select.value = '';
      }
    });

    for (const { text, value, selected } of options) {
      select.appendChild(this.createOptionElement(text, value, selected));
    }

    return select;
  }

  private createSvgElement({ path, viewBox, size, flip, direction }: RichTextToolbarOption['icon']) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('viewBox', viewBox);
    svg.innerHTML = path;
    svg.setAttribute('width', size.toString());
    svg.setAttribute('height', size.toString());

    const isCurrentDirection = (direction && direction === this.editor.dir) || 'ltr';

    if ((flip && !direction) || (flip && isCurrentDirection) || (!flip && !isCurrentDirection)) {
      svg.style.transform = 'scale(-1)';
    }

    return svg;
  }

  private createButtonElement(
    actionId: RichTextToolbarOption['action'],
    title: RichTextToolbarOption['title'],
    icon: RichTextToolbarOption['icon'],
    execCommand: (command: string, value?: string) => void
  ) {
    const button = document.createElement('button');
    const children = this.createSvgElement(icon);
    button.dataset.actionId = actionId;
    button.title = title;
    button.type = 'button';
    button.classList.add('rich-text__toolbar-button');
    button.appendChild(children);
    button.addEventListener('click', () => execCommand(actionId));

    return button;
  }

  private createInputElement(
    actionId: RichTextToolbarOption['action'],
    title: RichTextToolbarOption['title'],
    type: RichTextToolbarOption['type'],
    execCommand: (command: string, value?: string) => void
  ) {
    const input = document.createElement('input');
    input.dataset.actionId = actionId;
    input.title = title;
    input.type = type;
    input.addEventListener('change', (e) => execCommand(actionId, (e.target as HTMLInputElement).value));

    return input;
  }

  private initToolbar(execCommand: (command: string, value?: string) => void) {
    const toolbar = document.createElement('div');
    toolbar.classList.add('rich-text__toolbar');

    RICH_TEXT_TOOLBAR.forEach((elements, index) => {
      Object.entries(elements).forEach(([key, value]) => {
        const { action, element, title, options, icon, type, shortcut } = value;
        const attr = this.getAttribute(key);

        if (attr === NONE) {
          if (shortcut) {
            delete this.shortcutMap[shortcut];
          }

          return;
        } else if (shortcut) {
          this.shortcutMap = { ...(this.shortcutMap || {}), [shortcut]: () => execCommand(action) };
        }

        switch (element) {
          case RichTextElementTypes.SELECT:
            toolbar.appendChild(this.createSelectElement(action, attr || title, options || [], execCommand));
            break;
          case RichTextElementTypes.BUTTON:
            toolbar.appendChild(this.createButtonElement(action, attr || title, icon || { path: '', viewBox: '', size: 0 }, execCommand));
            break;
          case RichTextElementTypes.INPUT:
            toolbar.appendChild(this.createInputElement(action, attr || title, type!, execCommand));
            break;
          default:
            throw new Error(`Invalid element type ${element}`);
        }
      });

      if (index < RICH_TEXT_TOOLBAR.length - 1) {
        toolbar.appendChild(this.createSeparatorElement());
      }
    });

    const dynamicFields = this.getAttribute('dynamic-fields');

    if (dynamicFields) {
      const options = JSON.parse(dynamicFields);

      this.dynamicMap = options.reduce(
        (bulk: Record<string, string>, { text, value }: { text: string; value: string }) => ({ ...bulk, [value]: text }),
        {}
      );

      toolbar.appendChild(
        this.createSelectElement(
          'insert-dynamic-field',
          this.getAttribute('insert-dynamic-field') || 'Insert dynamic parameter',
          options,
          execCommand
        )
      );
    }

    this.toolbar = toolbar;
  }

  private insertDynamicPlaceholder(placeholder: string) {
    const selection = window.getSelection();

    if (!this.dynamicMap[placeholder]) {
      return;
    }

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const span = document.createElement('span');
      span.textContent = `[${this.dynamicMap[placeholder]}]`;
      span.style.backgroundColor = 'lightgray';
      span.style.padding = '0 2px';
      span.dataset.field = placeholder;
      span.contentEditable = 'false';

      range.deleteContents();
      range.insertNode(span);

      const newRange = document.createRange();
      newRange.selectNodeContents(span);
      newRange.collapse(false);

      const newSelection = window.getSelection();
      newSelection?.removeAllRanges();
      newSelection?.addRange(newRange);

      this.onChange();
    }
  }

  private initEditor(content: string | null = this.getAttribute('value')) {
    const divContent = document.createElement('div');
    divContent.innerHTML = content || '';
    const bodyContent = divContent.querySelector('#rich-text') as HTMLElement;
    const contentResult = bodyContent ? bodyContent?.innerHTML || '' : content || '';

    if (this.editor.innerHTML !== contentResult) {
      this.editor.innerHTML = contentResult;
    }

    this.editor.contentEditable = 'true';
    this.editor.dir = bodyContent?.style?.direction || this.getAttribute('direction') || 'ltr';
  }

  private fullScreen() {
    const handleEscape = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        this.fullScreen();
      }
    };

    if (this.container.classList.toggle('rich-text--full-screen')) {
      this.zIndex = getLastZIndex() + 1;
      this.container.style.zIndex = this.zIndex.toString();
      window.addEventListener('keydown', handleEscape);
    } else {
      this.container.style.zIndex = '';
      window.removeEventListener('keydown', handleEscape);
    }
  }

  private setDocDirection(direction?: 'ltr' | 'rtl') {
    this.editor.dir = direction || this.editor.dir === 'ltr' ? 'rtl' : 'ltr';
    this.onChange();
    this.initToolbar(this.execCommand.bind(this));
  }

  private getElementComputedStyle(node: Node) {
    const el = (node.nodeType === Node.TEXT_NODE ? node.parentElement : node) as Element;
    const computedStyle = el ? window.getComputedStyle(el) : ({} as CSSStyleDeclaration);

    return Object.entries(computedStyle).reduce(
      (bulk: Record<string, any>, [key, value]) => (value && isNaN(+key) ? { ...bulk, [key]: value } : bulk),
      {}
    );
  }

  private getElementComputedStyleKey(node: Node, key: keyof CSSStyleDeclaration) {
    return this.getElementComputedStyle(node)[key];
  }

  private setStyle(key: keyof CSSStyleDeclaration, value: string | Array<string>) {
    const currentHtml = this.undoBulk.push(this.editor.innerHTML);
    const selection = window.getSelection();

    if (!selection || selection.rangeCount === 0) {
      return;
    }

    const range = selection.getRangeAt(0);
    const nodeClone = range.cloneContents();
    const span = document.createElement('span');
    span.appendChild(nodeClone);
    range.deleteContents();
    range.insertNode(span);

    const currentValue = this.getElementComputedStyleKey(span, key);
    const hasStyle = span.firstChild?.nodeType === Node.ELEMENT_NODE && !!(span.firstChild as HTMLElement)?.style[key];
    this.clearStyle(span, key);

    span.style[key as any] = (() => {
      if (typeof value === 'string' && currentValue === value) {
        return hasStyle ? '' : 'none';
      } else if (typeof value === 'string') {
        return value;
      }

      const index = value.findIndex((val) => val === currentValue);
      const nextIndex = index + 1 === value.length ? 0 : index + 1;

      return value[nextIndex];
    })();

    if (span.parentElement?.childNodes.length === 1 && span.parentElement?.firstChild === span) {
      span.parentElement.replaceWith(span);
    }

    if (currentHtml !== this.undoBulk.length) {
      this.redoBulk.push(this.editor.innerHTML);
    }
  }

  private clearStyle(node?: Node, keys?: keyof CSSStyleDeclaration | Array<keyof CSSStyleDeclaration>) {
    const currentHtml = this.undoBulk.push(this.editor.innerHTML);
    const activeNode = node || window.getSelection()?.getRangeAt(0).commonAncestorContainer;

    const bulkKey = (() => {
      if (keys) {
        return Array.isArray(keys) ? keys : [keys];
      }

      return Object.keys(this.getElementComputedStyle(activeNode!));
    })();

    const bulkNodes = Array.from(activeNode?.childNodes || []).filter(
      (node) => node.nodeType === Node.ELEMENT_NODE && !Object.keys((node as HTMLElement).dataset).length
    );

    for (const childNode of bulkNodes) {
      const elementNode = childNode as HTMLElement;

      bulkKey.forEach((key) => {
        elementNode.style[key as any] = '';
      });

      const computedStyle = this.getElementComputedStyle(elementNode);
      const isSpanWithoutStyle = elementNode.tagName === 'SPAN' && !computedStyle.length;

      if (isSpanWithoutStyle) {
        const parentNode = elementNode.parentNode;

        if (parentNode) {
          while (elementNode.firstChild) {
            parentNode.insertBefore(elementNode.firstChild, elementNode);
          }

          parentNode.removeChild(elementNode);
        }
      } else if (childNode.childNodes?.length) {
        this.clearStyle(childNode, keys);
      }
    }

    if (currentHtml !== this.undoBulk.length && !node && !keys) {
      this.redoBulk.push(this.editor.innerHTML);
    }
  }

  private execCommand(command: string, value?: string) {
    this.editor.focus();

    switch (command) {
      case 'insert-dynamic-field':
        this.insertDynamicPlaceholder(value || '');
        break;
      case 'full-screen':
        this.fullScreen();
        break;
      case 'doc-direction':
        this.setDocDirection();
        break;
      case 'undo':
        const undo = this.undoBulk.pop();
        this.redoBulk.push(undo || '');
        this.editor.innerHTML = undo || '';
        break;
      case 'redo':
        const redo = this.redoBulk.pop();
        this.undoBulk.push(redo || '');
        this.editor.innerHTML = redo || '';
        break;
      case 'fontsize':
        this.setStyle('fontSize', value!);
        break;
      case 'bold':
        this.setStyle('fontWeight', ['700', '400']);
        break;
      case 'underline':
        this.setStyle('textDecoration', 'underline');
        break;
      case 'removeformat':
        this.clearStyle();
        this.undoBulk.push(this.editor.innerHTML);
        break;
      default:
        this.undoBulk.push(this.editor.innerHTML);
        document.execCommand(command, false, value);
        break;
    }

    this.onChange();
  }

  private onChange() {
    const htmlBody = `<div id="rich-text" style="direction: ${this.editor.dir}">${this.editor.innerHTML}</div>`;

    this.dispatchEvent(new CustomEvent('change', { detail: { htmlBody, direction: this.editor.dir } }));
    this.dispatchEvent(new CustomEvent('insert-dynamic'));
  }

  private rgbToHex(rgb: string) {
    return (
      '#' +
      rgb
        .split(', ')
        .map((val) => parseInt(val).toString(16).padStart(2, '0'))
        .join('')
        .toUpperCase()
    );
  }

  private handleKeyboardShortcut(event: KeyboardEvent) {
    const key = event.key.toLowerCase() as keyof typeof this.shortcutMap;

    if ((event.ctrlKey || event.metaKey) && this.shortcutMap[key]) {
      event.preventDefault();
      this.shortcutMap[key]();
    }

    this.updateActiveState.bind(this);
  }

  private updateActiveState(event?: Event) {
    event?.preventDefault();
    const toolbarSelects = this.toolbar.querySelectorAll('select[data-action-id]');
    const toolbarButtons = this.toolbar.querySelectorAll('button[data-action-id]');
    const inputButtons = this.toolbar.querySelectorAll('input[data-action-id]');

    for (const selectElement of Array.from(toolbarSelects) as Array<HTMLSelectElement>) {
      const value = selectElement.dataset.actionId && document.queryCommandValue(selectElement.dataset.actionId);

      const option = Array.from(selectElement.options).find((selectOption) => value && selectOption.value === value);
      selectElement.selectedIndex = option ? option.index : 0;
      selectElement.style.color = !option?.index ? '#A2A2A2' : 'inherit';
    }

    for (const buttonElement of Array.from(toolbarButtons) as Array<HTMLButtonElement>) {
      const active = !!buttonElement.dataset.actionId && !!document.querySelector(buttonElement.dataset.actionId);
      buttonElement.classList.toggle('active', active);
    }

    for (const inputElement of Array.from(inputButtons) as Array<HTMLInputElement>) {
      const value = inputElement.dataset.actionId && document.queryCommandValue(inputElement.dataset.actionId);

      if (!value) {
        return;
      }

      inputElement.value = this.rgbToHex(value);
    }
  }

  private addEventListeners() {
    this.editor.addEventListener('keydown', this.handleKeyboardShortcut.bind(this));
    this.editor.addEventListener('keyup', this.updateActiveState.bind(this));
    this.editor.addEventListener('click', this.updateActiveState.bind(this));
    this.editor.addEventListener('input', this.onChange.bind(this));
    this.toolbar.addEventListener('click', this.updateActiveState.bind(this));
  }

  connectedCallback() {
    this.style.display = 'flex';
    this.style.flexDirection = 'column';
    this.container.style.height = '100%';
    this.container.appendChild(this.toolbar);
    this.container.appendChild(this.editor);
    this.appendChild(this.container);
  }
}

customElements.define('rich-text', RichTextEditor);
