import i18n from '@/plugins/i18n';
import router from '@/router';
import { vzComponents } from '@shared/components';
import { vzDirectives } from '@shared/directives';
import { app } from '@/main';
import { createPinia } from 'pinia';
import { authAction, authCheck } from '@/store/auth/helpers';
import { isMobile, isPWA } from '@shared/helpers';

const setViewport = () => {
  if (isPWA || isMobile) {
    document.querySelector('meta[name="viewport"]')?.setAttribute('content', 'width=device-width, initial-scale=1.0, user-scalable=no');
  }
};

export default () => {
  setViewport();

  const pinia = createPinia();

  app.use(i18n).use(router).use(pinia).use(vzComponents).use(vzDirectives);

  app.config.globalProperties.$auth = authCheck;
  app.config.globalProperties.$can = authAction;
  app.mount('#app');
};
