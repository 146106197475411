import { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';

const JobOffer = () => import(/* webpackChunkName: "job-offer" */ '@/views/job/views/offer/job-offer.view.vue');

const jobOfferRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.FREELANCE.JOB_OFFER],
  name: Modules.FREELANCE.JOB_OFFER,
  component: JobOffer,
  meta: {
    isUnprotected: true,
    hideMenu: true,
    parentName: Modules.JOBS,
  },
};

export default jobOfferRoutes;
