<template>
  <svg
    :class="['icon-svg', { 'icon-svg--clickable': clickable, 'icon-svg--loading': loading }]"
    :style="{ width: `${size} !important`, height: `${size} !important`, minWidth: `${size} !important`, maxWidth: `${size} !important` }"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    :viewBox="viewBox"
    v-bind="{ ...$attrs, ...bind }"
    v-html="icon"
    @mouseover="() => (hover = true)"
    @mouseout="() => (hover = false)"
  />
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { type IconName, type IconType, ICONS, type SvgIcon, IconPrefix } from '@/shared/components/icon/icon.type';
import { type ColorsMap } from '@/shared/services/css-service/types/colors';
import type { SizeUnit } from '@shared/types';
import { convertRemToPixel } from '@shared/helpers';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';

const props = defineProps({
  name: { type: String as PropType<IconName>, required: true },
  type: { type: [String, Array] as PropType<IconType | [IconType, IconType]>, default: 'solid' },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  size: { type: String as PropType<SizeUnit>, default: '1.25rem' },
  loading: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  fallback: { type: String as PropType<IconName>, default: undefined },
  strokeColor: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  strokeWidth: { type: [String, Number] as PropType<`${string}` | number>, default: 2 },
});

const iconType = computed((): IconPrefix => props.name.substring(0, props.name.indexOf(':')) as IconPrefix);
const iconName = computed((): SvgIcon => props.name.slice(props.name.indexOf(':') + 1) as SvgIcon);
const iconColor = computed((): string | undefined => (props.color ? getThemeColor(props.color) : undefined));
const fallbackIconName = computed((): SvgIcon => props.fallback?.slice(props.name.indexOf(':') + 1) as SvgIcon);

const hover = ref<boolean>(false);

const icon = computed((): string | undefined => {
  if (iconType.value !== 'svg') {
    return;
  }

  const svgIcon = fallbackIconName.value ? ICONS[iconName.value] || ICONS[fallbackIconName.value] : ICONS[iconName.value];
  const svgType = Array.isArray(props.type) ? (hover.value ? props.type[1] : props.type[0]) : props.type;

  return svgIcon?.[svgType] || svgIcon?.regular || svgIcon?.solid;
});

const viewBox = computed(() => {
  if (iconType.value !== 'svg') {
    return;
  }

  return (ICONS[iconName.value] || ICONS[fallbackIconName.value])?.viewBox || `0 0 ${props.size} ${props.size} `;
});

const bindSize = computed(() => convertRemToPixel(props.size, true));

const bind = computed(() => {
  const strokeColor = props.strokeColor ? getThemeColor(props.strokeColor) : undefined;

  return {
    color: iconColor.value,
    height: bindSize.value,
    width: bindSize.value,
    aspect: 1,
    ...(props.clickable ? { role: 'button' } : {}),
    ...(strokeColor ? { 'stroke-color': strokeColor, 'stroke-width': props.strokeWidth } : {}),
  };
});
</script>

<style lang="scss" scoped>
.icon-svg {
  padding: 0.125rem;
  aspect-ratio: 1/1 !important;

  &--clickable {
    cursor: pointer;
    opacity: 0.9;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
