import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app__container" }

import '@shared/services/api-service/helpers/register-api-service';
import '@shared/services/api-service/helpers/add-api-interceptors';
import { computed, defineAsyncComponent, onMounted, watch } from 'vue';
import { useVisualViewport } from '@shared/composables';
import ThemeService from '@shared/services/css-service/theme-service';
import { useRoute } from 'vue-router';
import { IS_IMPERSONATE_MODE, IS_LOGGED_IN, IS_PARTIAL_SIGN_UP, LOAD_LOCAL_AUTH_STATE, TERMS_OF_SERVICE_SIGN_AT } from '@/store/auth/auth.constants';
import EmployeeViewModal from '@/views/lobby/modals/employee-view-modal.component.vue';
import VzPopup from '@shared/components/popup/vz-popup.vue';
import { useAuthStore } from '@/store/auth';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const TermsOfServiceModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/components/terms-of-service-modal.vue'));
const LoginModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/modals/login-modal/login-modal.vue'));
const TopNavbar = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/components/top-navbar/app-top-navbar.vue'));
const BottomNavbar = defineAsyncComponent(
  () => import(/* webpackChunkName: "main" */ '@/views/lobby/components/bottom-navbar/app-bottom-navbar.vue')
);

const route = useRoute();

const authStore = useAuthStore();

const { [LOAD_LOCAL_AUTH_STATE]: loadLocalAuthState } = authStore;

const {
  [IS_PARTIAL_SIGN_UP]: isPartialSignUp,
  [IS_IMPERSONATE_MODE]: isImpersonate,
  [IS_LOGGED_IN]: isLoggedIn,
  [TERMS_OF_SERVICE_SIGN_AT]: termOfServiceSignAt,
} = storeToRefs(authStore);

const isSplashScreen = computed(() => route.meta.isSplashScreen);

onMounted(() => {
  useVisualViewport();
  loadLocalAuthState();
});

watch(
  () => isImpersonate.value,
  (newValue) => {
    ThemeService.set(newValue ? { name: 'warning' } : { name: 'default' });
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isSplashScreen.value)
      ? (_openBlock(), _createBlock(_unref(TopNavbar), { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(EmployeeViewModal),
    (!isSplashScreen.value)
      ? (_openBlock(), _createBlock(_unref(BottomNavbar), { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(VzPopup),
    (_unref(isLoggedIn) && !_unref(isImpersonate) && !_unref(termOfServiceSignAt) && !_unref(isPartialSignUp))
      ? (_openBlock(), _createBlock(_unref(TermsOfServiceModal), { key: 2 }))
      : _createCommentVNode("", true),
    ((!_unref(isLoggedIn) || _unref(isPartialSignUp)) && !isSplashScreen.value)
      ? (_openBlock(), _createBlock(_unref(LoginModal), { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})