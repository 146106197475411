<template>
  <div class="d-flex flex-column gap-4" :data-errors="validateMessage">
    <div v-if="!hideDate" class="d-flex gap-4 justify-center flex-ch-1 flex-grow-1">
      <vz-calendar v-model="vDateFrom" :class="{ 'aspect-1-1 max-width-320': !singleDay }" hide-details :disabled="disabled" />

      <vz-calendar v-if="!singleDay" v-model="vDateTo" :class="{ 'aspect-1-1 max-width-320': !singleDay }" hide-details :disabled="disabled" />
    </div>

    <div v-if="!hideTime" class="d-flex gap-4 flex-ch-1">
      <vz-datepicker v-model="vDateFrom" hide-details :disabled="disabled" type="time" label="GENERAL.TIME_FROM" />

      <vz-datepicker v-model="vDateTo" hide-details :disabled="disabled" type="time" label="GENERAL.TIME_TO" />
    </div>

    <div :class="['vz-datepicker__error', { 'vz-datepicker__error--hidden': hideDetails }]">
      <p v-if="validateMessage" :class="{ 'vz-datepicker__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>
      <p v-else-if="errorMessage">{{ $t(errorMessage) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import dayjs from 'dayjs';
import { useTranslator } from '@/plugins/i18n/helpers';
import { useValidator } from '@shared/components/fields/helpers';
import { GlobalVariables } from '@shared/constants';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';

const props = defineProps({
  disabled: { type: Boolean, default: false },
  hideDate: { type: Boolean, default: false },
  hideTime: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  noTime: { type: Boolean, default: false },
  singleDay: { type: Boolean, default: false },
  initTimeFrom: { type: String as PropType<`${string}:${string}` | undefined>, default: undefined },
  initTimeTo: { type: String as PropType<`${string}:${string}` | undefined>, default: undefined },
  dateFrom: { type: Number as PropType<number | null | undefined>, required: true },
  dateTo: { type: Number as PropType<number | null | undefined>, required: true },
  errorMessage: { type: String as PropType<string | null>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules>, default: () => ({}) },
  enableOverlap: { type: Boolean, default: false },
});

const emit = defineEmits(['update:date-from', 'update:date-to']);

const t = useTranslator();

const getInitDateAndTime = (key: 'initTimeFrom' | 'initTimeTo') => {
  const [hours, minutes] = props[key]?.split(':')?.map((val) => +val) || [0, 0];
  const date = new Date().setHours(hours, minutes, 0, 0);

  return date.valueOf();
};

const vDateFrom = computed({
  get: (): number => (props.dateFrom ? props.dateFrom : getInitDateAndTime('initTimeFrom')),
  set: (value) => {
    if (props.noTime) {
      new Date(value).setHours(0, 0, 0, 0);
    }

    if (props.singleDay) {
      vDateTo.value = new Date(value).setHours(new Date(vDateTo.value).getHours());
    }

    emit('update:date-from', value.valueOf());
  },
});

const vDateTo = computed({
  get: (): number => (props.dateTo ? props.dateTo : getInitDateAndTime('initTimeTo')),
  set: (value) => emit('update:date-to', value.valueOf()),
});

const { validateMessage: validateMessage1, isTouched: isTouched1 } = useValidator(
  computed(() => props.dateFrom),
  computed(() => {
    return {
      ...props.rules,
      ...(!props.enableOverlap
        ? {
            max: [
              vDateTo.value,
              props.singleDay
                ? t('VALIDATE.TIME_FROM', { time: dayjs(vDateTo.value).format('H:mm') })
                : t('VALIDATE.DATE_FROM', { date: dayjs(vDateTo.value).format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT) }),
            ],
          }
        : {}),
    };
  }),
  'dateFrom'
);

const { validateMessage: validateMessage2, isTouched: isTouched2 } = useValidator(
  computed(() => props.dateTo),
  computed(() => {
    return {
      ...props.rules,
      ...(!props.enableOverlap
        ? {
            min: [
              vDateFrom.value,
              props.singleDay
                ? t('VALIDATE.TIME_TO', { time: dayjs(vDateFrom.value).format('H:mm') })
                : t('VALIDATE.DATE_TO', { date: dayjs(vDateFrom.value).format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT) }),
            ],
          }
        : {}),
    };
  }),
  'dateTo'
);

const isTouched = computed(() => isTouched1.value || isTouched2.value);
const validateMessage = computed(() => [validateMessage1.value, validateMessage2.value].filter((val) => val).join(` ${t('GENERAL.OR')} `));
</script>

<style scoped lang="scss"></style>
