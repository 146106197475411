import type { BaseId } from '@/shared/models';
import type { GetAuthSessionRes } from '@/store/auth/auth.type';
import { computed, type ComputedRef } from 'vue';
import { SESSION } from '@/store/auth/auth.constants';
import { useAuthStore } from '@/store/auth';
import { storeToRefs } from 'pinia';

export function useAuthUser(): {
  isMe: (userId?: BaseId, isRoot?: boolean) => boolean;
  isAdmin: ComputedRef<boolean>;
  activeUser: ComputedRef<GetAuthSessionRes | null | undefined>;
  isLoggedIn: ComputedRef<boolean>;
  myId: ComputedRef<string>;
  isImpersonate: ComputedRef<boolean>;
  countryPrefix: ComputedRef<number | undefined>;
  currencyType: ComputedRef<string | undefined>;
  activeLanguage: ComputedRef<string>;
  userLanguages: ComputedRef<Array<string>>;
  termOfServiceSignAt: ComputedRef<number>;
  isPartialSignUp: ComputedRef<boolean>;
} {
  const authStore = useAuthStore();
  const { [SESSION]: userState } = storeToRefs(authStore);

  const activeUser = computed((): GetAuthSessionRes | null => userState.value as GetAuthSessionRes);
  const isAdmin = computed((): boolean => userState.value?.role === 'ADMIN');
  const isLoggedIn = computed((): boolean => !!userState.value?._id);
  const activeId = computed((): string => userState.value?._id || '');
  const isImpersonate = computed((): boolean => !!userState.value?.isImpersonate);
  const currencyType = computed((): string | undefined => userState.value?.currency);
  const countryPrefix = computed((): number | undefined => userState.value?.countryPrefix);
  const activeLanguage = computed((): string => userState.value?.language || 'en');
  const userLanguages = computed((): Array<string> => [...(userState.value?.languages || [])]);
  const termOfServiceSignAt = computed((): number => userState.value?.termOfServiceSignAt || 0);
  const isPartialSignUp = computed((): boolean => userState.value?.hasSignUpCompleted === false);

  return {
    isMe: (userId?: BaseId): boolean => activeId.value === userId,
    myId: activeId,
    isAdmin,
    activeUser,
    isLoggedIn,
    isImpersonate,
    countryPrefix,
    currencyType,
    activeLanguage,
    userLanguages,
    termOfServiceSignAt,
    isPartialSignUp,
  };
}
