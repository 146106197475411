import { WEEKDAYS } from '@/shared/services/calendar-service/calendar.constants';
import dayjs from 'dayjs';

type Months = [number, number, number, number, number, number, number, number, number, number, number, number];
export type CalendarDay = { day: number; date: Date; text: string; isToday: boolean; isDifferentMonth: true };

export default class Calendar {
  public weekdays = WEEKDAYS;
  public date = new Date();
  public year: number = this.date.getFullYear();
  public month: number = this.date.getMonth() + 1;

  public matrix(year: number = this.year, monthIndex: number = this.month - 1): Array<CalendarDay> {
    this.year = year;
    this.month = monthIndex + 1;
    const monthDays = this.monthDays(year);
    const firstDayIndex = new Date(year, monthIndex, 1).getDay();
    const lastDayIndex = firstDayIndex + monthDays[monthIndex];

    return [...Array(6 * this.weekdays.length)].reduce((matrix: Array<CalendarDay>, _, index) => {
      if (index < firstDayIndex) {
        const offset: number = index - firstDayIndex + 1;
        const date = new Date(year, monthIndex, offset);
        const text = dayjs(date).format('YYYY-MM-DD');

        return [...matrix, { day: date.getDate(), date, text, isToday: date === new Date(), isDifferentMonth: true }];
      }

      if (index >= lastDayIndex) {
        const offset: number = index - lastDayIndex + 1;
        const date = new Date(year, monthIndex + 1, offset);
        const text = dayjs(date).format('YYYY-MM-DD');

        return [...matrix, { day: date.getDate(), date, text, isToday: date === new Date(), isDifferentMonth: true }];
      }

      const day: number = index - firstDayIndex + 1;
      const date = new Date(year, monthIndex, day);
      const text = dayjs(date).format('YYYY-MM-DD');

      return [...matrix, { day, date, text, isToday: date === new Date() }];
    }, []);
  }

  private isLeapYear(year: number): boolean {
    return year % 400 === 0 || year % 4 === 0;
  }

  private monthDays(year: number): Months {
    return [31, this.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  }
}
