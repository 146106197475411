import { createApp } from 'vue';
import App from './App.vue';
import './register-service-worker';
import '@/styles/app.scss';
import { TinyEmitter } from 'tiny-emitter';
import { GET_AUTH_SESSION, SESSION } from '@/store/auth/auth.constants';
import { container } from 'tsyringe';
import AuthService from '@/store/auth/auth.service';
import '@/shared/elements';
import authLocalStore from '@/store/auth/helpers/auth-local-store';
import appInit from '@/app-init';

export const app = createApp(App);
export const emitter = new TinyEmitter();

const authService = container.resolve(AuthService);

const getAuthSession = async (): Promise<void> => {
  const session = await authService[GET_AUTH_SESSION]();

  authLocalStore.save({ [SESSION]: session || null });
};

try {
  await getAuthSession();
} catch (e) {
  console.error(e);
} finally {
  appInit();

  setTimeout(() => {
    document.getElementById('preloader')?.remove();
  });
}
