import dayjs from 'dayjs';
import { GlobalVariables } from '@/shared/constants/global-variables';
import type { BaseDate } from '@/shared/models';

export const formattedDate = (
  time?: { dateFrom?: number; dateTo?: number } | number | string | undefined | null,
  dateFormat: string = GlobalVariables.FULL_DATE
): string | undefined => {
  if (typeof time === 'number' || typeof time === 'string') {
    return dayjs(time).format(dateFormat);
  }

  if (typeof time === 'object') {
    const { dateFrom, dateTo } = (time || {}) as { dateFrom?: BaseDate; dateTo?: BaseDate };

    if (!dateFrom) {
      return;
    }

    const from = dayjs(+(dateFrom! || dateTo!)).format(dateFormat);
    const to = dayjs(+(dateTo || dateFrom)).format(dateFormat);

    return from === to ? from : `${from} - ${to}`;
  }
};
