<template>
  <div class="d-flex flex-column align-center justify-center fill-height fill-width bg-light py-4">
    <vz-svg-href class="fill-width height-p50 px-16" :name="splashImage" />

    <div class="mb-6">
      <vz-error-alert v-if="errors?.errorMessage?.length" :errors="errors" />

      <p v-else>{{ $t(noDataText) }}</p>
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';

const props = defineProps({
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  noDataText: { type: String, default: 'DATA.NO_DATA_AVAILABLE' },
  noDataImage: { type: String as PropType<SplashName>, default: 'no-data' },
});

const splashImage = computed((): SplashName => {
  if (props.errors?.errorMessage?.length) {
    return 'server-error';
  }

  return props.noDataImage;
});
</script>
