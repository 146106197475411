import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { BaseAddress, BaseOptions } from '@shared/models';
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { computed, onMounted, type PropType } from 'vue';
import { LIST_ADDRESSES } from '@/store/client/client.constants';
import { useAsync } from '@shared/composables';
import { useGeneralStore } from '@/store/client';


export default /*@__PURE__*/_defineComponent({
  __name: 'location-select',
  props: {
  itemText: { type: String as PropType<keyof BaseAddress>, default: 'title' },
  modelValue: { type: Object as PropType<any | Array<any>>, required: true },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
},
  emits: ['update:model-value'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const vModel = computed({
  get: (): BaseAddress | null => props.modelValue || null,
  set: (value) => emit('update:model-value', value),
});

const { [LIST_ADDRESSES]: listAddresses } = useGeneralStore();

const listPlacesRequest = useAsync<BaseOptions<BaseAddress>>(listAddresses as (query: string) => Promise<BaseOptions<BaseAddress>>, {
  initState: { results: (!props.modelValue || Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) || null },
});

onMounted(() => {
  listPlacesRequest.results.value = (!props.modelValue || Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) || null;
});

return (_ctx: any,_cache: any) => {
  const _component_vz_select = _resolveComponent("vz-select")!

  return (_openBlock(), _createBlock(_component_vz_select, _mergeProps({
    modelValue: vModel.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vModel).value = $event)),
    fixed: "",
    debounce: "500",
    type: "select",
    "item-id-key": "key",
    "item-text": ['title', 'address', 'place'],
    rules: __props.rules,
    items: _unref(listPlacesRequest).results.value || [],
    loading: _unref(listPlacesRequest).loading.value,
    "error-message": __props.errorMessage
  }, _ctx.$attrs, {
    onSearch: _unref(listPlacesRequest).call
  }), null, 16, ["modelValue", "rules", "items", "loading", "error-message", "onSearch"]))
}
}

})