import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-center align-center"
}

import { computed, ref, defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { routeTo, useAsync } from '@shared/composables';
import { GET_EMPLOYEE_DETAILS } from '@/views/employee/store/employee.constants';
import { EMPLOYEE_VIEW_MODAL_IGNORE_ROUTE_LIST } from '@/views/lobby/modals/employee-view-modal.constants';
import { isMobile } from '@shared/helpers';
import { useEmployeeStore } from '@/views/employee/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'employee-view-modal.component',
  setup(__props) {

const EmployeeFeed = defineAsyncComponent(
  () => import(/* webpackChunkName: "profile" */ '@/views/employee/components/profile/components/employee-feed.vue')
);

const { [GET_EMPLOYEE_DETAILS]: getEmployeeDetailsAction } = useEmployeeStore();

const getEmployeeDetailsRequest = useAsync(getEmployeeDetailsAction as (payload: { userId: string }) => Promise<void>);

const route = useRoute();
const vzOverlayRef = ref();

const userId = computed((): string | undefined => (route.query?.userId as string) || undefined);
const isLoading = computed((): boolean => getEmployeeDetailsRequest.loading.value);
const isActive = computed((): boolean => !!userId.value && !getEmployeeDetailsRequest.loading.value);
const isEnabled = computed((): boolean => !EMPLOYEE_VIEW_MODAL_IGNORE_ROUTE_LIST.includes(route.name as string));

watch(
  userId,
  async (id) => {
    if (!id) {
      vzOverlayRef.value?.close();

      return;
    }

    getEmployeeDetailsAction({ userId: id });
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_spinner = _resolveComponent("vz-spinner")!
  const _component_vz_overlay = _resolveComponent("vz-overlay")!

  return (isEnabled.value)
    ? (_openBlock(), _createBlock(_component_vz_overlay, _mergeProps({
        key: 0,
        ref_key: "vzOverlayRef",
        ref: vzOverlayRef,
        class: "employee-modal",
        size: _unref(VzOverlayEnum).LARGE,
        "is-open": isActive.value || isLoading.value
      }, _ctx.$attrs, {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(routeTo)({ query: { userId: null } })))
      }), {
        default: _withCtx(() => [
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_vz_spinner, { size: "96px" })
              ]))
            : (isActive.value)
              ? (_openBlock(), _createBlock(_unref(EmployeeFeed), {
                  key: 1,
                  class: "flex-grow-1 overflow-hidden",
                  "fixed-about": !_unref(isMobile)
                }, null, 8, ["fixed-about"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 16, ["size", "is-open"]))
    : _createCommentVNode("", true)
}
}

})