import { BaseDate } from '@shared/models';
import { uniqueKey } from '@shared/helpers/unique-key';

export interface CreateIcsEvent {
  dateFrom: BaseDate;
  dateTo: BaseDate;
  title: string;
  description?: string;
  address?: string;
  uid?: string;
  freeFlag?: boolean;
  organizer?: { fullName: string; email: string };
  updatedAt?: BaseDate;
}

export const createIcsEvent = (event: CreateIcsEvent | Array<CreateIcsEvent>) => {
  const events = Array.isArray(event) ? event : [event];
  const ics = ['BEGIN:VCALENDAR', 'VERSION:2.0', 'PRODID:-//hitbook//NONSGML v1.0//EN', events.map(createEvent).join('\n'), 'END:VCALENDAR'].join(
    '\n'
  );

  const blob = new Blob([ics], { type: 'text/calendar' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'events.ics';
  link.click();
};

// Helper function to generate a single event
const createEvent = (event: CreateIcsEvent) => {
  const escapeIcalText = (text: string | undefined) => text?.replace(/\\/g, '\\\\').replace(/,/g, '\\,').replace(/;/g, '\\;').replace(/\n/g, '\\n');

  const organizerField = event.organizer
    ? `ORGANIZER;CN=${escapeIcalText(event.organizer.fullName || '')}:mailto:${escapeIcalText(event.organizer.email)}`
    : undefined;

  const ics = {
    BEGIN: 'VEVENT',
    UID: [event.uid || uniqueKey(Math.random()), '@hitbook.io'].join(''),
    DTSTAMP: formatICalDate(new Date()),
    SEQUENCE: new Date().valueOf(),
    'LAST-MODIFIED': formatICalDate(event.updatedAt || new Date()),
    DTSTART: formatICalDate(event.dateFrom),
    DTEND: formatICalDate(event.dateTo),
    SUMMARY: escapeIcalText(event.title),
    DESCRIPTION: escapeIcalText(event.description),
    LOCATION: escapeIcalText(event.address),
    TRANSP: event.freeFlag ? 'TRANSPARENT' : 'OPAQUE',
    ORGANIZER: organizerField,
    END: 'VEVENT',
  };

  return Object.entries(ics).reduce((acc, [key, value]) => {
    return value ? [acc, [key, value].join(':')].join('\n') : acc;
  }, '');
};

// Helper function to format dates for iCal
const formatICalDate = (input: BaseDate | Date) => {
  const date = input instanceof Date ? input : new Date(input);

  return date.toISOString().replace(/-|:|\.\d+/g, ''); // iCal format: YYYYMMDDTHHMMSSZ
};
