<template>
  <div :class="['notification-card', 'clickable', { skeleton: loading }]" @click="onClick">
    <vz-image v-if="item?.image" class="border-radius-50" :src="item.image" width="42" height="42" />

    <vz-avatar v-else-if="user" :model-value="user?.avatar" :hid="user?._id" size="42" :verified="user?.isVerified" />

    <div v-else-if="icon" class="bg-active border-radius-50 width-42 height-42 aspect-1-1 d-flex align-center justify-center">
      <vz-icon size="28px" :name="icon" />
    </div>

    <div class="notification-card__content ps-2 text-body-1">
      <p v-if="title && !user" class="text-title-1">{{ title }}</p>

      <p v-else-if="user" class="text-title-1">{{ user.firstName }} {{ user.lastName }}</p>

      <p class="c-mono-400">{{ createdAt }}</p>

      <p>{{ item.type }}</p>

      <p v-if="title && user">{{ title }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NotificationItem } from '@/views/notifications/store/notification.types';
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type PropType } from 'vue';
import { GlobalVariables } from '@/shared/constants/global-variables';
import { routeTo } from '@shared/composables';
import { isToday } from '@/views/calendar/helpers';
import dayjs from 'dayjs';
import router from '@/router';

const props = defineProps({
  loading: { type: Boolean, default: false },
  clickable: { type: Boolean, default: true },
  item: { type: Object as PropType<NotificationItem>, required: true },
});

const routes = router.getRoutes();

const route = computed(() => props.item?.route);
const title = computed(() => props.item?.title);
const user = computed(() => props.item?.from);

const icon = computed(() => {
  return (routes.find(({ name }) => name === route.value?.name)?.meta?.icon as IconName) || undefined;
});

const createdAt = computed(() => {
  const createdAt = props.item?.createdAt;

  if (!createdAt) {
    return null;
  }

  return isToday(createdAt) ? dayjs(createdAt).fromNow() : dayjs(createdAt).format(GlobalVariables.FULL_DATE_AND_TIME);
});

const onClick = async (): Promise<void> => {
  const { route } = props.item;

  if (!route) {
    return;
  }

  await routeTo(route);
};
</script>

<style lang="scss" scoped>
.notification-card {
  position: relative;
  display: flex;
  overflow: hidden;
  height: min-content;
  border-bottom: var(--border-regular);
  margin: 0 0.5rem;
  padding: 1rem 0.5rem;

  &__content {
    > p:not(:last-child) {
      @include max-lines(1);
    }

    > p:last-child {
      @include max-lines(2);
    }
  }
}
</style>
