import dayjs from 'dayjs';
import { extractRangeOfDates } from '@/views/calendar/helpers/extract-range-of-dates';

export const eventsMapper = <T>(
  items: Array<T & { dateFrom: number; dateTo: number }>
): Record<string, Array<T & { dateFrom: number; dateTo: number }>> => {
  return extractRangeOfDates(items).reduce((map: Record<number, Array<T & { dateFrom: number; dateTo: number }>>, date) => {
    const events = items.filter((event) => {
      const dateFrom = dayjs(event.dateFrom).startOf('day').valueOf();
      const dateTo = dayjs(event.dateTo).endOf('day').valueOf();
      const currentDate = dayjs(date).startOf('day').valueOf();

      return dateFrom <= currentDate && currentDate <= dateTo;
    });

    return !events.length ? map : { ...map, [date]: events };
  }, {});
};
