import { AvailableLanguages } from './available-languages.enum';
import { createI18n } from 'vue-i18n';

import '@/plugins/dayjs-init';

import enLang from '@shared/locale/en.json';
import enShared from '@shared/locale/en-shared.json';

import heLang from '@shared/locale/he.json';
import heShared from '@shared/locale/he-shared.json';

import 'dayjs/locale/he';
import 'dayjs/locale/en';

const en = { ...enShared, ...enLang };
const he = { ...heShared, ...heLang };

type DictionarySchema = typeof en;

const i18n = createI18n({
  locale: AvailableLanguages.en,
  fallbackLocale: AvailableLanguages.en,
  globalInjection: true,
  silentTranslationWarn: true,
  messages: { en, he },
});

export default i18n;
