<template>
  <top-navbar v-if="!isSplashScreen" />

  <div class="app__container">
    <router-view />
  </div>

  <employee-view-modal />

  <bottom-navbar v-if="!isSplashScreen" />

  <vz-popup />

  <terms-of-service-modal v-if="isLoggedIn && !isImpersonate && !termOfServiceSignAt && !isPartialSignUp" />

  <login-modal v-if="(!isLoggedIn || isPartialSignUp) && !isSplashScreen" />
</template>
<script setup lang="ts">
import '@shared/services/api-service/helpers/register-api-service';
import '@shared/services/api-service/helpers/add-api-interceptors';
import { computed, defineAsyncComponent, onMounted, watch } from 'vue';
import { useVisualViewport } from '@shared/composables';
import ThemeService from '@shared/services/css-service/theme-service';
import { useRoute } from 'vue-router';
import { IS_IMPERSONATE_MODE, IS_LOGGED_IN, IS_PARTIAL_SIGN_UP, LOAD_LOCAL_AUTH_STATE, TERMS_OF_SERVICE_SIGN_AT } from '@/store/auth/auth.constants';
import EmployeeViewModal from '@/views/lobby/modals/employee-view-modal.component.vue';
import VzPopup from '@shared/components/popup/vz-popup.vue';
import { useAuthStore } from '@/store/auth';
import { storeToRefs } from 'pinia';

const TermsOfServiceModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/components/terms-of-service-modal.vue'));
const LoginModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/modals/login-modal/login-modal.vue'));
const TopNavbar = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/components/top-navbar/app-top-navbar.vue'));
const BottomNavbar = defineAsyncComponent(
  () => import(/* webpackChunkName: "main" */ '@/views/lobby/components/bottom-navbar/app-bottom-navbar.vue')
);

const route = useRoute();

const authStore = useAuthStore();

const { [LOAD_LOCAL_AUTH_STATE]: loadLocalAuthState } = authStore;

const {
  [IS_PARTIAL_SIGN_UP]: isPartialSignUp,
  [IS_IMPERSONATE_MODE]: isImpersonate,
  [IS_LOGGED_IN]: isLoggedIn,
  [TERMS_OF_SERVICE_SIGN_AT]: termOfServiceSignAt,
} = storeToRefs(authStore);

const isSplashScreen = computed(() => route.meta.isSplashScreen);

onMounted(() => {
  useVisualViewport();
  loadLocalAuthState();
});

watch(
  () => isImpersonate.value,
  (newValue) => {
    ThemeService.set(newValue ? { name: 'warning' } : { name: 'default' });
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.app {
  &___container {
    height: var(--content-height);
  }
}
</style>
