import io from 'socket.io-client';
import type { SocketRoom } from '@shared/services/socket-service/socket-client.types';
import { SocketEnum } from '@/shared/services/socket-service/socket.enum';
import { BaseId } from '@shared/models';

const SOCKET_URL = process.env.VUE_APP_API_URL!;

type SocketEvent = { id?: string; key: string };
type SocketSetup = { uid: string; key?: string };

export default class SocketClientService {
  public static url: string = SOCKET_URL;
  public static socket: ReturnType<typeof io> | null = null;
  public static listeners: Array<{ eventName: string; callback: (...arg: any) => void }> = [];
  public static emits: Array<{ eventName: string; data: any }> = [];

  private static init(uid: string, key?: string): void {
    this.emit(SocketEnum.SETUP, { uid, key });
    this.listeners.forEach(({ eventName, callback }) => this.on(eventName, callback));
    this.emits.forEach(({ eventName, data }) => setTimeout(() => this.emit(eventName, data)));
  }

  private static connect(uid: string, key?: string): void {
    if (!this.isConnected()) {
      this.socket = io(this.url, { reconnection: true, reconnectionDelay: 1000, reconnectionDelayMax: 5000 });
      this.socket.on('connect', () => setTimeout(() => this.init(uid, key), 1000));
    } else {
      this.init(uid, key);
    }
  }

  public static setup(config: SocketSetup) {
    const { uid, key } = config || {};

    this.connect(uid, key);
  }

  public static on(event: string | SocketEvent, callback: (...arg: any) => void, hasUniqueName = false): void {
    const eventName = typeof event === 'string' ? event : [event.key, event.id].join(':');

    if (!this.listeners.find((cache) => cache.eventName === eventName && (hasUniqueName || cache.callback === callback))) {
      this.listeners.push({ eventName, callback });
    }

    if (this.socket && !this.socket.hasListeners(eventName)) {
      this.socket.on(eventName, callback);
    }
  }

  public static off(event: string | SocketEvent, callback?: (...arg: any) => void): void {
    const eventName = typeof event === 'string' ? event : [event.key, event.id].join(':');

    this.listeners = this.listeners.filter((cache) => !(cache.eventName === eventName && (!callback || cache.callback === callback)));

    if (!this.socket) {
      return;
    }

    if (!callback) {
      this.socket.removeAllListeners(eventName);
    } else {
      this.socket.off(eventName);
    }
  }

  public static send<T>(eventName: string, roomName: string, data: T): void {
    this.socket?.emit(eventName, roomName, data);
  }

  public static emit(eventName: string, ...data: any): void | string {
    if (!this.socket?.connected) {
      if (!this.emits.find((channel) => channel.eventName === eventName)) {
        this.emits.push({ eventName, data });
      }

      return;
    }

    this.socket.emit(eventName, ...data);
    return `emit:${eventName} ${data}`;
  }

  public static join(room: SocketRoom | Array<SocketRoom>, userId?: BaseId): void {
    const rooms = (Array.isArray(room) ? room : [room]).map(({ roomId, roomKey }) => ({ roomId, roomKey, userId }));
    this.emit(SocketEnum.JOIN_CHAT, ...rooms);
  }

  public static leave(room: SocketRoom | Array<SocketRoom>): void {
    const rooms = (Array.isArray(room) ? room : [room]).map(({ roomId, roomKey }) => ({ roomId, roomKey }));
    this.emit(SocketEnum.LEAVE_CHAT, ...rooms);
  }

  public static terminate(clearEvent = true): void {
    this.listeners.forEach(({ eventName }) => this.off(eventName));
    this.socket?.disconnect();
    this.socket = null;

    if (clearEvent) {
      this.listeners = [];
      this.emits = [];
    }
  }

  public static status(): ReturnType<typeof io> | null {
    return this.socket;
  }

  public static isConnected(): boolean {
    return !!this.socket?.connected;
  }
}
