// actions
export const SIGN_IN = 'sign-in';
export const SIGN_UP = 'sign-up';
export const SIGN_OUT = 'sign-out';
export const GET_AUTH_SESSION = 'get-auth-session';
export const FORGOT_PASSWORD = 'forgot-password';
export const CHANGE_PASSWORD = 'change-password';
export const METHOD_OTP_VALIDATE = 'method-otp-validate';
export const IMPERSONATE_TO = 'impersonate-to';
export const SAVE_TOKEN = 'save-token';

export const GOOGLE_AUTHORIZATION = 'google-authorization';

// state
export const SESSION = 'session';
export const ASSIGNED = 'assigned';
export const VERIFICATION_EXPIRED_AT = 'verification-expired-at';

// mutations
export const COMPLETE_SIGN_UP = 'complete-sign-up';
export const SET_ASSIGNEE = 'set-assigned-modules';
export const LOAD_LOCAL_AUTH_STATE = 'load-local-auth-state';
export const SET_LANGUAGE = 'set-language';

// getters
export const LANGUAGE = 'language';
export const LANGUAGES = 'languages';
export const USER_EMAIL = 'user-email';
export const USER_PHONE = 'user-phone';
export const ASSIGNED_MODULES = 'assigned-modules';
export const ASSIGNED_USER_ID = 'assigned-user-id';
export const ASSIGNEE_LIST = 'assignee-list';
export const AUTH_MODULES = 'aut-modules';
export const IS_LOGGED_IN = 'is-logged-in';
export const CURRENCY = 'currency';
export const IS_PARTIAL_SIGN_UP = 'is-partial-sign-up';
export const IS_IMPERSONATE_MODE = 'is-impersonate-mode';
export const IS_ADMIN = 'is-admin';
export const TERMS_OF_SERVICE_SIGN_AT = 'terms-of-service-sign-at';
