import type { CreatePdfReq, GetAttachmentRes, GetVideoDetailsRes, ListCurrenciesRes, ListPhoneCodesRes } from '@/store/client/models';
import type { ListCompaniesRes } from '@/views/employee/types';
import type { BaseAddress, BaseOptions } from '@shared/models';
import { defineStore, storeToRefs } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { computed, ref } from 'vue';
import {
  CREATE_PDF,
  CURRENCIES,
  GET_ATTACHMENT,
  GET_DATA,
  GET_VIDEO_DETAILS,
  LIST_ADDRESSES,
  LIST_COMPANIES,
  LIST_CURRENCIES,
  LIST_PHONE_CODES,
  PHONE_CODES,
} from '@/store/client/client.constants';
import { CURRENCY } from '@/store/auth/auth.constants';
import { useAuthStore } from '@/store/auth';
import { container } from 'tsyringe';
import ClientServices from '@/store/client/service/client.services';

const generalServices = container.resolve(ClientServices);

export const useGeneralStore = defineStore(StoreNamespace.GENERAL_MODULE, () => {
  const { [CURRENCY]: getCurrency } = storeToRefs(useAuthStore());

  const state = ref({
    [PHONE_CODES]: [] as ListPhoneCodesRes['list'],
    [CURRENCIES]: [] as ListCurrenciesRes['list'],
  });

  const listCurrencies = async (): Promise<ListCurrenciesRes['list']> => {
    const res = await generalServices[LIST_CURRENCIES](getCurrency.value);
    state.value = { ...state.value, [CURRENCIES]: res?.list || [] };

    return res?.list || [];
  };

  const listPhoneCodes = async (): Promise<ListPhoneCodesRes['list']> => {
    const res = await generalServices[LIST_PHONE_CODES]();
    state.value = { ...state.value, [PHONE_CODES]: res?.list || [] };

    return res?.list || [];
  };

  const listCompanies = async (query: string): Promise<ListCompaniesRes['companies']> => {
    if ((query || []).length < 3) {
      return [];
    }

    const res = await generalServices[LIST_COMPANIES](query);

    return res?.companies || [];
  };

  const listAddresses = async (query: string): Promise<BaseOptions<BaseAddress>> => {
    if ((query || []).length < 3) {
      return [];
    }

    const res = await generalServices[LIST_ADDRESSES](query);

    return res?.options || [];
  };

  const getVideoDetails = async (videoUrl: string): Promise<GetVideoDetailsRes> => {
    return await generalServices[GET_VIDEO_DETAILS](videoUrl);
  };

  const getAttachment = async ({ id, noCache }: { id: string; noCache?: boolean }): Promise<GetAttachmentRes> => {
    return await generalServices[GET_ATTACHMENT](id, noCache);
  };

  const getData = async (hid: string): Promise<Blob> => {
    return await generalServices[GET_DATA](hid);
  };

  const createPdf = async (data: CreatePdfReq): Promise<string> => {
    return await generalServices[CREATE_PDF](data);
  };

  return {
    // getters
    [CURRENCIES]: computed(() => state.value?.[CURRENCIES] || []),
    [PHONE_CODES]: computed(() => state.value?.[PHONE_CODES] || []),

    // actions
    [LIST_CURRENCIES]: listCurrencies,
    [LIST_PHONE_CODES]: listPhoneCodes,
    [LIST_COMPANIES]: listCompanies,
    [LIST_ADDRESSES]: listAddresses,
    [GET_VIDEO_DETAILS]: getVideoDetails,
    [GET_ATTACHMENT]: getAttachment,
    [GET_DATA]: getData,
    [CREATE_PDF]: createPdf,
  };
});
