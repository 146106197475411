import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "vz-modal__header" }
const _hoisted_2 = {
  key: 0,
  class: "text-title-3"
}
const _hoisted_3 = {
  key: 1,
  class: "mt-2 text-body-2"
}
const _hoisted_4 = {
  key: 2,
  class: "vz-modal__progress_bar mt-2"
}
const _hoisted_5 = {
  key: 0,
  class: "fill-width d-flex align-center justify-center"
}
const _hoisted_6 = { class: "vz-modal__actions" }
const _hoisted_7 = { class: "vz-modal__actions-slots" }
const _hoisted_8 = { class: "vz-modal__actions-main" }

import type { VzOverlayRef } from '@shared/components/overlay/vz-overlay.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, type PropType, ref } from 'vue';
import { useAsync, useServerErrorsMapper } from '@shared/composables';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { useFormValidator } from '@shared/components/fields/helpers';
import { modalStepHandler } from '@shared/components/overlay/helpers/modal-step-handler';
import VzDivider from '@shared/components/vz-divider.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-modal',
  props: {
  hideHeaderDivider: { type: Boolean, default: false },
  hideContentDivider: { type: Boolean, default: false },
  nonResizable: { type: Boolean, default: false },
  nonDraggable: { type: Boolean, default: false },
  hideCancel: { type: Boolean, default: false },
  cancelText: { type: String, default: 'GENERAL.CANCEL' },
  hideSubmit: { type: Boolean, default: false },
  submitText: { type: String, default: 'GENERAL.SUBMIT' },
  hideActions: { type: Boolean, default: false },
  overflowY: { type: Boolean, default: true },
  isOpen: { type: Boolean, default: false },
  title: { type: String as PropType<string | undefined>, default: undefined },
  subtitle: { type: String as PropType<string | undefined>, default: undefined },
  fitContent: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  loading: { type: Boolean, default: false },
  disableEscape: { type: Boolean, default: false },
  warningCallback: {
    type: [Boolean, Function] as PropType<boolean | ((payload?: any) => Promise<unknown>)>,
    default: false,
  },
  hideCloseButton: { type: Boolean, default: false },
  openEvent: { type: String as PropType<string | undefined>, default: undefined },
  closeEvent: { type: String as PropType<string | undefined>, default: undefined },
  openCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  closeCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  submitCallback: { type: Function as PropType<() => Promise<unknown> | unknown | undefined>, default: undefined },
  size: { type: String as PropType<keyof typeof VzOverlayEnum | undefined>, default: VzOverlayEnum.SMALL },
  steps: { type: Array as PropType<Array<string> | undefined>, default: undefined },
  hideStepper: { type: Boolean, default: false },
  nextCallbacks: {
    type: [Object] as PropType<Record<string, () => void | Promise<void>> | undefined>,
    default: undefined,
  },
  backCallbacks: {
    type: [Object] as PropType<Record<string, () => void | Promise<void>> | undefined>,
    default: undefined,
  },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
},
  emits: ['update:is-open', 'open', 'close', 'submit'],
  setup(__props, { expose: __expose, emit: __emit }) {

const emit = __emit;

const props = __props;

const contentClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));
const isLoading = computed(() => submitLoading.value || isStepLoading.value);
const errors = computed(() => openError.value || vzOverlayRef.value?.errors || props.errors || submitErrors.value || null);
const fieldErrors = useServerErrorsMapper(computed(() => vzOverlayRef.value?.errors || props.errors || null));

const {
  call: onSubmit,
  loading: submitLoading,
  error: submitErrors,
} = useAsync(
  async () => {
    if (!validate()) {
      return;
    }

    await props.submitCallback?.();
    emit('submit');
  },
  { successCallback: () => vzOverlayRef.value?.close(true) }
);

const {
  call: openModalCallback,
  loading: openLoading,
  error: openError,
} = useAsync(async (payload: any) => {
  resetStep();
  await props.openCallback?.(payload);
});

const contentRef = ref<Element | undefined>(undefined);
const vzOverlayRef = ref<VzOverlayRef>(undefined);

const validate = (isSilent?: boolean): boolean => {
  const isValid = useFormValidator(contentRef, isSilent);

  return isValid();
};

const vIsOpen = computed({
  get: () => props.isOpen,
  set: (value) => emit('update:is-open', value),
});

const { handleNext, handleBack, resetStep, isLastStep, isFirstStep, activeStep, activeStepIndex, isStepLoading } = modalStepHandler({
  validate,
  steps: computed(() => props.steps),
  nextCallbacks: props.nextCallbacks,
  backCallbacks: props.backCallbacks,
});

const close = (isForce?: boolean) => vzOverlayRef.value?.close(isForce);
const open = () => vzOverlayRef.value?.open();

__expose({
  validate,
  close: (isForce?: boolean) => vzOverlayRef.value?.close(isForce),
  open: (payload?: any) => vzOverlayRef.value?.open(payload),
  errors: computed(() => vzOverlayRef.value?.errors || props.errors),
});

return (_ctx: any,_cache: any) => {
  const _component_vz_error_alert = _resolveComponent("vz-error-alert")!
  const _component_vz_spinner = _resolveComponent("vz-spinner")!
  const _component_vz_button = _resolveComponent("vz-button")!
  const _component_vz_overlay = _resolveComponent("vz-overlay")!

  return (_openBlock(), _createBlock(_component_vz_overlay, _mergeProps({
    ref_key: "vzOverlayRef",
    ref: vzOverlayRef,
    "is-open": vIsOpen.value,
    "onUpdate:isOpen": _cache[2] || (_cache[2] = ($event: any) => ((vIsOpen).value = $event))
  }, _ctx.$attrs, {
    class: ['vz-modal', ...contentClass.value],
    "non-resizable": __props.nonResizable,
    "non-draggable": __props.nonDraggable,
    "fit-content": __props.fitContent,
    size: __props.size,
    loading: __props.loading,
    "disable-escape": __props.disableEscape,
    "hide-close-button": __props.hideCloseButton,
    "open-event": __props.openEvent,
    "open-callback": _unref(openModalCallback),
    "close-event": __props.closeEvent,
    "close-callback": __props.closeCallback,
    "warning-callback": __props.warningCallback,
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close', $event))),
    onOpen: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('open', $event))),
    "onUpdate:isOpen": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:is-open', $event)))
  }), {
    activator: _withCtx(({ toggle }) => [
      _renderSlot(_ctx.$slots, "activator", { toggle: toggle })
    ]),
    default: _withCtx(() => [
      (__props.title || __props.subtitle || errors.value?.errorMessage || _ctx.$slots['header'] || _ctx.$slots[`header-${_unref(activeStep)}`])
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "header"),
              _renderSlot(_ctx.$slots, `header-${_unref(activeStep)}`),
              (__props.title)
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t(__props.title)), 1))
                : _createCommentVNode("", true),
              (__props.subtitle)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(__props.subtitle)), 1))
                : _createCommentVNode("", true),
              (!__props.hideStepper && (__props.steps || []).length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.steps, (name, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: name,
                        class: _normalizeClass([
              'vz-modal__progress_bar-item',
              { 'vz-modal__progress_bar-item--active': _unref(activeStep) === name, 'vz-modal__progress_bar-item--passed': _unref(activeStepIndex) > index },
            ])
                      }, _toDisplayString(index + 1), 3))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            (!__props.hideHeaderDivider)
              ? (_openBlock(), _createBlock(VzDivider, {
                  key: 0,
                  class: "my-4"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_vz_error_alert, {
              class: "pa-2 mx-4",
              errors: errors.value
            }, null, 8, ["errors"])
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        ref_key: "contentRef",
        ref: contentRef,
        class: _normalizeClass(['vz-modal__content', { 'overflow-y-auto': __props.overflowY }])
      }, [
        (_unref(openLoading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_vz_spinner, {
                class: "mx-auto",
                size: "96px"
              })
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _renderSlot(_ctx.$slots, "default", {
                close: close,
                open: open,
                fieldErrors: _unref(fieldErrors),
                serverErrors: errors.value,
                validate: validate
              }),
              _renderSlot(_ctx.$slots, _unref(activeStep), {
                close: close,
                open: open,
                fieldErrors: _unref(fieldErrors),
                serverErrors: errors.value,
                validate: validate
              })
            ], 64))
      ], 2),
      (!__props.hideActions)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!__props.hideContentDivider)
              ? (_openBlock(), _createBlock(VzDivider, {
                  key: 0,
                  class: "my-2"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "additional-actions", {
                  close: close,
                  open: open
                }),
                _renderSlot(_ctx.$slots, `actions-${_unref(activeStep)}`, {
                  close: close,
                  open: open
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "actions", {
                  close: close,
                  open: open
                }, () => [
                  (!__props.hideCancel && (!__props.hideCloseButton || !_unref(isFirstStep)))
                    ? (_openBlock(), _createBlock(_component_vz_button, {
                        key: 0,
                        class: "min-width-120",
                        text: _unref(isFirstStep) ? __props.cancelText : 'GENERAL.BACK',
                        disabled: isLoading.value,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(handleBack)(vzOverlayRef.value?.close)))
                      }, null, 8, ["text", "disabled"]))
                    : _createCommentVNode("", true),
                  (!__props.hideSubmit)
                    ? (_openBlock(), _createBlock(_component_vz_button, {
                        key: 1,
                        class: "min-width-120",
                        text: _unref(isLastStep) ? __props.submitText : 'GENERAL.NEXT',
                        loading: isLoading.value,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(handleNext)(_unref(onSubmit))))
                      }, null, 8, ["text", "loading"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["is-open", "class", "non-resizable", "non-draggable", "fit-content", "size", "loading", "disable-escape", "hide-close-button", "open-event", "open-callback", "close-event", "close-callback", "warning-callback"]))
}
}

})