import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "upcoming-events__day-header text-uppercase text-title-1"
}

import type { GetUpcomingEventsRes, GetUpcomingEventsReq } from 'src/views/calendar/types';
import type { CalendarEvent } from 'src/views/calendar/types';
import type { VzInfinityScrollRef } from '@shared/components/infinity-scroll/infinity-scroll.type';
import { defineAsyncComponent, type PropType, ref, watch } from 'vue';
import { GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { eventsMapper } from '@/views/calendar/helpers/events-mapper';
import { formattedDate } from '../helpers';
import { isToday } from '@/views/calendar/helpers/is-today';
import { routeTo } from '@shared/composables';
import { useCalendarStore } from '@/views/calendar/store';
import EmptyState from '@/components/empty-state.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'upcoming-events.view',
  props: {
  disabled: { type: Boolean, default: false },
  callback: { type: Function as PropType<((payload?: GetUpcomingEventsReq) => Promise<GetUpcomingEventsRes>) | undefined>, default: undefined },
},
  setup(__props, { expose: __expose }) {

const EventCard = defineAsyncComponent(() => import(/* webpackChunkName: "calendar" */ '@/views/calendar/components/event-card.vue'));

const props = __props;

const { [GET_UPCOMING_EVENTS]: getUpcomingEventsAction } = useCalendarStore();

const getUpcomingEventsRequest = (props.callback || getUpcomingEventsAction) as (payload?: GetUpcomingEventsReq) => Promise<GetUpcomingEventsRes>;

const { activeUser } = useAuthUser();
const infinityScrollRef = ref<VzInfinityScrollRef>(undefined);

const isUpcomingIndex = ref<number | undefined>(undefined);

const getData = (data: Array<CalendarEvent>): Record<string, Array<CalendarEvent>> => {
  const map = eventsMapper(data);
  isUpcomingIndex.value = Object.keys(map).findIndex((date) => +date > Date.now());

  return map;
};

const onClick = (item: CalendarEvent): void => {
  if (!item.route) {
    return;
  }

  routeTo({ ...item.route }, { isRedirectFrom: true });
};

watch(
  () => [activeUser.value?._id],
  () => {
    if (!activeUser.value?._id) {
      return;
    }

    infinityScrollRef.value?.reset();
  }
);

__expose({ reset: () => infinityScrollRef.value?.reset() });

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_infinity_scroll = _resolveComponent("vz-infinity-scroll")!

  return (_openBlock(), _createBlock(_component_vz_infinity_scroll, {
    ref_key: "infinityScrollRef",
    ref: infinityScrollRef,
    class: "upcoming-events",
    disabled: __props.disabled,
    callback: _unref(getUpcomingEventsRequest)
  }, {
    "no-data": _withCtx(() => [
      _createVNode(EmptyState, {
        "no-data-text": "CALENDAR.THERE_IS_NO_UPCOMING_EVENT",
        "no-data-image": "calendar"
      })
    ]),
    default: _withCtx(({ data }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getData(data), (events, date, dateIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: dateIndex,
          class: "upcoming-events__day"
        }, [
          (events.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (!_unref(isToday)(+date))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_vz_icon, {
                        name: "svg:calendar",
                        size: "1.25rem"
                      }),
                      _createElementVNode("span", null, _toDisplayString(_unref(formattedDate)({ dateFrom: +date })), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(events, (event, eventIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: eventIndex,
              class: "upcoming-events__day-grid gap-2"
            }, [
              _createVNode(_unref(EventCard), {
                class: "clickable",
                item: event,
                onClick: ($event: any) => (onClick(event))
              }, null, 8, ["item", "onClick"])
            ]))
          }), 128))
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["disabled", "callback"]))
}
}

})