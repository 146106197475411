<template>
  <div v-layout="(value: LayoutEnum) => (layout = value)" class="d-flex flex-column fill-height overflow-hidden">
    <slot name="header" />

    <div
      :class="[
        'split-screen flex-grow-1',
        { 'split-screen--horizontal': !hasBreakpoint, 'split-screen--vertical': hasBreakpoint, 'split-screen--resizing': isResizing },
      ]"
    >
      <template v-if="$slots['1'] && $slots['2'] && !isShowSingleSlot">
        <div ref="pane1" class="split-screen__pane">
          <slot name="1" :has-breakpoint="hasBreakpoint" />
        </div>

        <hr class="split-screen__resizer" @mousedown="onStart" @touchstart.prevent="onStart" />

        <div ref="pane2" class="split-screen__pane">
          <slot name="2" :has-breakpoint="hasBreakpoint" />
        </div>
      </template>

      <div v-else class="split-screen__single-slot">
        <slot v-if="breakpointSlot && $slots[breakpointSlot]" :name="breakpointSlot" :has-breakpoint="hasBreakpoint" />
        <slot v-else-if="$slots['1']" name="1" :has-breakpoint="hasBreakpoint" />
        <slot v-else-if="$slots['2']" name="2" :has-breakpoint="hasBreakpoint" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeUnmount, computed, watch, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { LayoutEnum } from '@shared/constants';

const { locale } = useI18n();

const props = defineProps({
  minSize: {
    type: [Number, String] as PropType<number | `${number}`>,
    default: 50,
    validator: (value: number | string) => +value >= 0 && +value <= 100,
  },
  maxSize: {
    type: [Number, String] as PropType<number | `${number}`>,
    default: 70,
    validator: (value: number | string) => +value >= 0 && +value <= 100,
  },
  initialSize: {
    type: [Number, String] as PropType<number | `${number}`>,
    default: 60,
    validator: (value: number | string) => +value >= 0 && +value <= 100,
  },
  breakpoint: {
    type: String as PropType<LayoutEnum | null>,
    default: LayoutEnum.md,
    validator: (value: LayoutEnum | null) => value === null || Object.values(LayoutEnum).includes(value),
  },
  breakpointSlot: { type: [Number, String] as PropType<1 | 2 | '1' | '2' | null>, default: null },
});

const pane1 = ref<HTMLElement | null>(null);
const pane2 = ref<HTMLElement | null>(null);
const isResizing = ref(false);
const layout = ref<LayoutEnum>(LayoutEnum.xl);

const isRtl = computed(() => document.dir === 'rtl' || ['ar', 'he', 'fa', 'ur'].includes(locale.value));

const breakpointArray = computed(() => {
  const values = Object.values(LayoutEnum);

  return props.breakpoint ? values.slice(values.indexOf(LayoutEnum.xs), values.indexOf(props.breakpoint) + 1) : [];
});

const hasBreakpoint = computed(() => {
  if (props.breakpoint === null) {
    return false;
  }

  return breakpointArray.value.includes(layout.value);
});

const isShowSingleSlot = computed(() => props.breakpointSlot && hasBreakpoint.value);

let startPos = 0;
let startPane1Size = 0;

const initializePaneSizes = (initialSize: number): void => {
  if (!pane1.value || !pane2.value) {
    return;
  }

  const size = Math.min(Math.max(initialSize, +props.minSize), +props.maxSize);

  pane1.value.style.flexBasis = `${size}%`;
  pane2.value.style.flexBasis = `${100 - size}%`;
};

const getEventPosition = (event: MouseEvent | TouchEvent): { x: number; y: number } => {
  if (event instanceof MouseEvent) {
    return { x: event.clientX, y: event.clientY };
  } else if (event instanceof TouchEvent) {
    const touch = event.touches[0];
    return { x: touch.clientX, y: touch.clientY };
  }
  return { x: 0, y: 0 };
};

const onMove = (event: MouseEvent | TouchEvent): void => {
  if (!isResizing.value || !pane1.value || !pane2.value) {
    return;
  }

  const { x, y } = getEventPosition(event);
  const delta = !hasBreakpoint.value ? (isRtl.value ? startPos - x : x - startPos) : y - startPos;

  const newPane1Size = startPane1Size + delta;
  const containerSize = !hasBreakpoint.value ? pane1.value.parentElement!.offsetWidth : pane1.value.parentElement!.offsetHeight;

  const newPane1Percentage = (newPane1Size / containerSize) * 100;
  const restrictedSize = Math.min(Math.max(newPane1Percentage, +props.minSize), +props.maxSize);

  pane1.value.style.flexBasis = `${restrictedSize}%`;
  pane2.value.style.flexBasis = `${100 - restrictedSize}%`;
};

const onEnd = (): void => {
  isResizing.value = false;
  window.removeEventListener('mousemove', onMove);
  window.removeEventListener('mouseup', onEnd);
  window.removeEventListener('touchmove', onMove);
  window.removeEventListener('touchend', onEnd);
};

const onStart = (event: MouseEvent | TouchEvent): void => {
  isResizing.value = true;
  const { x, y } = getEventPosition(event);
  startPos = !hasBreakpoint.value ? x : y;
  startPane1Size = !hasBreakpoint.value ? pane1.value!.offsetWidth : pane1.value!.offsetHeight;

  window.addEventListener('mousemove', onMove);
  window.addEventListener('mouseup', onEnd);
  window.addEventListener('touchmove', onMove);
  window.addEventListener('touchend', onEnd);
};

watch(
  () => [pane1, pane2],
  () => {
    const init = Math.min(Math.max(+props.initialSize, +props.minSize), +props.maxSize);

    initializePaneSizes(init);
  },
  { immediate: true, deep: true }
);

onBeforeUnmount(() => {
  window.removeEventListener('mousemove', onMove);
  window.removeEventListener('mouseup', onEnd);
  window.removeEventListener('touchmove', onMove);
  window.removeEventListener('touchend', onEnd);
});

defineExpose({ layout: computed(() => layout.value), hasBreakpoint });
</script>

<style scoped lang="scss">
.split-screen {
  display: flex;
  overflow: hidden;

  &--horizontal {
    flex-direction: row;
  }

  &--vertical {
    flex-direction: column;
  }

  &--resizing {
    user-select: none;
  }

  &__pane {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    overflow: hidden;
  }

  &__single-slot {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__resizer {
    border-color: var(--color-background-active);
    cursor: ew-resize;
    width: 12px;
    height: 100%;
    position: relative;
    z-index: 1;
    opacity: 0.5;

    &:hover {
      opacity: 0.8;
    }
  }

  &--vertical {
    .split-screen__resizer {
      cursor: ns-resize; // Vertical cursor
      width: 100%;
      height: 12px;
    }
  }
}
</style>
