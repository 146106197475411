<template>
  <vz-data-table
    class="system-log-table__container"
    auto-fetch
    :headers="tableHeaders"
    :items="items"
    :page="currentPage"
    :sort="pagination.sort"
    :total-items="totalItems"
    :loading="searchRequest.loading.value"
    :errors="searchRequest.error.value || {}"
    :delete-callback="onDelete"
    @clear="onClear"
    @search="onSearch"
    @select:item="onSelect"
    @update:page="onPageChange"
    @update:sort="onSortChange"
  >
    <template #search-panel="{ errors }">
      <vz-select v-model="filter.type" clearable label="BACKOFFICE.SYSTEM_LOG.TYPE" :items="logTypes" />

      <vz-input v-model="filter.value" clearable label="GENERAL.SEARCH" :rules="{ maxLength: [LENGTH.TITLE] }" :error-message="errors?.value" />

      <vz-async-select
        v-model="filter.userId"
        class="col-flex-6"
        clearable
        label="BACKOFFICE.SYSTEM_LOG.USER"
        :callback="searchEmployeesRequest"
        :item-value="(value: ProfileBaseDetails) => value._id"
        :item-text="(value: ProfileBaseDetails) => [value.firstName, value.lastName].join(' ')"
        :error-message="errors?.value"
      >
        <template #default="{ item }">
          <employee-strip class="pa-0" clickable avatar-size="28" :item="item" />
        </template>

        <template #badge>
          <employee-strip class="pa-0" avatar-size="28" :hid="filter.userId" />
        </template>
      </vz-async-select>

      <vz-datepicker v-model="filter.dateFrom" clearable label="GENERAL.DATE_FROM" :max="filter.dateTo" :error-message="errors?.dateFrom" />

      <vz-datepicker v-model="filter.dateTo" clearable label="GENERAL.DATE_TO" :min="filter.dateFrom" :error-message="errors?.dateTo" />
    </template>

    <template #title="{ item }">
      <div class="d-flex gap-2 align-center">
        <div class="ltr">{{ item.title }}</div>
        <vz-badge font-size="0.75rem" :text="item.type" :color="SystemLogColorEnum[item.type as keyof typeof SystemLogColorEnum]" />
      </div>
    </template>

    <template #user="{ item }">
      <employee-strip
        v-if="item.user"
        class="pa-0"
        horizontal
        show-company
        font-size="1rem"
        :item="item.user"
        avatar-size="42"
        @click.stop
        @select="routeTo({ query: { userId: $event?._id } })"
      />

      <div v-else class="d-flex align-center gap-1">
        <vz-icon name="svg:user-settings" size="42px" />
        <div>
          <div class="text-title-1">{{ $t('GENERAL.SYSTEM') }}</div>
        </div>
      </div>
    </template>
  </vz-data-table>
</template>

<script setup lang="ts">
import type { TableHeader } from '@/shared/components/tables/models/table-header';
import type { BasePagination } from '@shared/models';
import type { ProfileBaseDetails, SearchEmployeesReq, SearchEmployeesRes } from '@/views/employee/types';
import { computed } from 'vue';
import { routeTo, useAsync } from '@/shared/composables';
import dayjs from 'dayjs';
import { GlobalVariables } from '@/shared/constants/global-variables';
import { useBackofficeLogStore } from '@/views/backoffice/system-log/store';
import { DELETE_SYSTEM_LOG, GET_SYSTEM_LOG, SEARCH_SYSTEM_LOG } from '@/views/backoffice/system-log/store/system-log.constants';
import { storeToRefs } from 'pinia';
import { openDeletePopup } from '@shared/components/popup/helpers';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';
import { SEARCH_EMPLOYEES } from '@/views/employee/store/employee.constants';
import { LENGTH } from '@shared/constants';
import { SystemLogColorEnum, SystemLogEnum } from '@/views/backoffice/system-log/constants/system-log.enum';
import { useTranslator } from '@/plugins/i18n/helpers';
import { SearchSystemLogRes } from '@/views/backoffice/system-log/store/system-log.types';
import { useEmployeeStore } from '@/views/employee/store';

const t = useTranslator();

const tableHeaders: Array<TableHeader> = [
  { title: 'GENERAL.TITLE', value: 'title', sortable: true, style: { minWidth: '120px' } },
  { title: 'GENERAL.USER', value: 'user', sortable: true, class: 'pa-0', style: { minWidth: '120px' } },
  { title: 'GENERAL.CREATED_AT', value: 'createdAt', sortable: true, style: { minWidth: '120px' } },
];

const { [SEARCH_EMPLOYEES]: searchEmployeesAction } = useEmployeeStore();
const searchEmployeesRequest = searchEmployeesAction as (payload: SearchEmployeesReq) => Promise<SearchEmployeesRes>;

const useBackofficeJobStore = useBackofficeLogStore();

const { data, pagination, filter } = storeToRefs(useBackofficeJobStore);
const {
  resetSystemLog,
  [SEARCH_SYSTEM_LOG]: searchSystemLog,
  [GET_SYSTEM_LOG]: getSystemLog,
  [DELETE_SYSTEM_LOG]: deleteSystemLog,
} = useBackofficeJobStore;

const searchRequest = useAsync<SearchSystemLogRes>(searchSystemLog as (page?: BasePagination) => Promise<SearchSystemLogRes>, {
  errorsCleanTimeout: 30 * 1000,
});

const getRequest = useAsync<void>(getSystemLog as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
});

const deleteRequest = useAsync<void>(deleteSystemLog as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
  successCallback: searchRequest.call,
});

const currentPage = computed(() => pagination.value?.index || 0);
const totalItems = computed(() => pagination.value?.total || 0);
const items = computed(() =>
  data.value.map((item) => ({ ...item, createdAt: dayjs(item.createdAt).format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT) }))
);
const logTypes = computed(() => Object.values(SystemLogEnum).map((value) => ({ value, title: t(`BACKOFFICE.SYSTEM_LOG.TYPES.${value}`) })));

const onSearch = (page?: Partial<BasePagination>): void => {
  searchRequest.call(page);
};

const onClear = (): void => {
  resetSystemLog();
  onSearch();
};

const onPageChange = (index: number): void => {
  onSearch({ index });
};

const onSortChange = (sort: BasePagination['sort']): void => {
  onSearch({ sort });
};

const onDelete = async ({ _id, title }: { _id: string; title?: string }): Promise<void> => {
  await openDeletePopup(() => deleteRequest.call(_id), { name: title });
};

const onSelect = ({ _id }: { _id: string }): void => {
  getRequest.call(_id);
};
</script>
