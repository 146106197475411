import type { ColorsMap } from '@shared/services/css-service/types';
import type { IconName } from '@shared/components/icon/icon.type';
import type { SizeUnit } from '@shared/types';
import { useAsync } from '@shared/composables';
import { emitter } from '@/main';
import { CLOSE_POPUP, OPEN_POPUP } from '@shared/components/popup/popup.constants';

type Action<T> = {
  label: string;
  color?: ColorsMap;
  callback?: () => Promise<T> | T;
};

type Config<T> = {
  title?: string | [string, Record<string, string | number | undefined>?];
  description: string | [string, Record<string, string | number | undefined>?];
  actions: Array<Action<T>>;
  icon?: { name: IconName; color?: ColorsMap; size?: SizeUnit };
};

export const openPopup = async <T = void>(config: Config<T>): Promise<T> => {
  return new Promise((resolve, reject) => {
    emitter.emit(OPEN_POPUP, {
      title: undefined,
      ...config,
      actions: config.actions.map(({ callback, ...rest }) => ({
        ...rest,
        ...{
          request: useAsync((callback || (() => null)) as () => Promise<T>, {
            successCallback: (data: T) => {
              resolve(data);
              emitter.emit(CLOSE_POPUP);
            },
            failedCallback: (error) => {
              reject(error);
              emitter.emit(CLOSE_POPUP);
            },
          }),
        },
      })),
    });

    const onCloseEvent = () => {
      emitter.off(CLOSE_POPUP, onCloseEvent);
    };

    emitter.on(CLOSE_POPUP, onCloseEvent);
  });
};
