import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, vShow as _vShow } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = {
  key: 0,
  class: "text-ellipsis"
}
const _hoisted_3 = {
  key: 0,
  class: "me-1"
}
const _hoisted_4 = ["readonly", "placeholder", "disabled", "aria-label"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "vz-select__badge"
}

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { BaseOption } from '@/shared/models';
import type { ItemFunctionArg } from '@/shared/components/fields/vz-select/models';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import { computed, nextTick, onMounted, type PropType, ref, useSlots, watch } from 'vue';
import { scrollToView, uniqueKey } from '@/shared/helpers';
import { useValidator } from '@/shared/components/fields/helpers';
import { useGetItemText, useGetItemValue, useItemValuesRevert } from '@/shared/components/fields/vz-select/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-select',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  multiple: { type: Boolean, default: false },
  hideCheckbox: { type: Boolean, default: false },
  modelValue: {
    type: [Object, Number, String, Array] as PropType<any | Array<any> | Record<any, any> | undefined | null>,
    required: true,
  },
  autoOpen: { type: Boolean, default: false },
  autoCloseOnSelect: { type: Boolean as PropType<boolean | undefined>, default: undefined },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  debounce: { type: [String, Number], default: 0 },
  items: { type: Array as PropType<Array<any> | undefined | null>, default: undefined },
  itemIdKey: { type: String as PropType<string | undefined>, default: undefined },
  itemText: { type: [Function, String, Array] as PropType<((item: ItemFunctionArg) => any) | string | Array<string> | null>, default: 'title' },
  itemValue: { type: [Function, String] as PropType<((item: ItemFunctionArg) => any) | string>, default: 'value' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  onlyFromList: { type: Boolean, default: false },
  clearable: { type: Boolean, default: true },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  noDataText: { type: String, default: 'DATA.NO_DATA_AVAILABLE' },
  textManipulationCallback: { type: Function as PropType<(item: any) => any>, default: (item: any) => item },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
},
  emits: ['update:model-value', 'search'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const t = useTranslator();
const slots = useSlots();

const inputId = uniqueKey(props.label);
const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const debounceTimeout = ref<ReturnType<typeof setTimeout>>(0);
const inputRef = ref<HTMLInputElement | undefined>(undefined);
const searchValue = ref<string | null>(null);
const selectedIndex = ref<number>(-1);
const isForceOpen = ref<boolean>(false);
const isListShown = ref<boolean>(false);
const isAutoCloseOnSelect = ref<boolean>(false);

const onClick = (): void => {
  if (!props.multiple && !props.readonly && !props.disabled) {
    isForceOpen.value = true;
  }
};

const autoCompleteShown = computed(
  (): boolean =>
    !!props.items && isFocus.value && !isSelected.value && (!!searchValue.value || (!searchValue.value && props.autoOpen) || !!slots['badge'])
);

const optionsList = computed(() =>
  (props.items || []).map((item: BaseOption<any> | string) => (typeof item === 'string' ? { title: t(item), value: item.split('.').pop() } : item))
);
const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const getTitle = useGetItemText(props.itemText, props.textManipulationCallback);
const getValue = useGetItemValue(props.itemValue);

const selectionValueModal = useItemValuesRevert(
  computed(() => optionsList.value || []),
  props.multiple,
  getValue,
  getTitle,
  props.itemIdKey
);

const vModel = computed({
  get: (): any => selectionValueModal(props.modelValue),
  set: (value) => emit('update:model-value', value),
});

const externalError = computed(() => {
  if (props.fieldErrors && props.name && props.fieldErrors[props.name]) {
    return props.fieldErrors[props.name];
  }

  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});
const isSelected = computed((): boolean => !!vModel.value?.length && !props.multiple);
const isClearable = computed(
  () => (props.multiple ? !!((props.modelValue as Array<any>) || []).length : !!props.modelValue) && !props.readonly && !props.disabled
);

const itemList = computed((): Array<any> => {
  const value = searchValue.value;
  const regExp = new RegExp(`(${value})`, 'gi');

  return value
    ? optionsList.value?.filter((item) => {
        const text = getTitle(item);

        return (typeof text === 'string' ? text : JSON.stringify(item)).match(regExp);
      })
    : optionsList.value;
});

const top = ref<number>(0);
const width = ref<number>(0);
const maxHeight = ref<number>(0);

const debounce = (value: string | null) => {
  clearTimeout(debounceTimeout.value);

  debounceTimeout.value = setTimeout(() => {
    emit('search', value);
    inputRef.value?.focus();
  }, +props.debounce);
};

const isItemSelected = (value: any): boolean => {
  return !!(Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]).find(
    (item) => JSON.stringify(getValue(value)) === JSON.stringify(getValue(item))
  );
};

const onFocus = (): void => {
  selectedIndex.value = -1;
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  setTimeout(() => {
    let element = inputRef.value?.parentElement;

    while (element && !element.scrollTop) {
      element = element.parentElement;
    }

    const visualViewport = window.visualViewport!;
    const container = inputRef.value?.parentElement;
    const { top: boundingTop = 0, height: boundingHeight = 0 } = container?.getBoundingClientRect() || {};
    top.value = boundingHeight + (props.hideDetails ? 2 : 32) - (element?.scrollTop || 0);
    maxHeight.value = Math.max((visualViewport.height || window.innerHeight) - (boundingTop + boundingHeight + 32), 96);
    width.value = container?.getBoundingClientRect().width || 200;
    isFocus.value = true;
  }, 250);
};

const onBlur = (): void => {
  blurTimeout.value = setTimeout(() => {
    searchValue.value = null;
    isFocus.value = false;
    isForceOpen.value = false;
  }, 250);
};

const onInput = (): void => debounce(searchValue.value);

const onQuerySearch = (ev: KeyboardEvent): void => {
  switch (ev.key) {
    case 'Backspace':
      if (!!searchValue.value?.length || slots['badge']) {
        return;
      } else if (Array.isArray(props.modelValue)) {
        emit('update:model-value', props.modelValue.slice(0, -1));
      } else {
        emit('update:model-value', null);
      }
      break;
    case 'ArrowDown':
      selectedIndex.value = Math.min(selectedIndex.value + 1, (itemList.value?.length || 0) - 1);
      scrollToView(`.vz-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'ArrowUp':
      selectedIndex.value = Math.max(selectedIndex.value - 1, 0);
      scrollToView(`.vz-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'Enter':
      if (autoCompleteShown.value) {
        onSelectFromList(itemList.value![selectedIndex.value]);
      }
      ev.preventDefault();
      break;
    case 'Escape':
      inputRef.value?.blur();
      ev.preventDefault();
      break;
    default:
      selectedIndex.value = 0;
      scrollToView(`.vz-select__list-item-${selectedIndex.value}`);
      break;
  }
};

const onSelectFromList = (item: Record<string, any>): void => {
  if (!isAutoCloseOnSelect.value && !slots['badge']) {
    inputRef.value?.focus();
  } else {
    inputRef.value?.blur();
    isForceOpen.value = false;
  }

  if (props.multiple) {
    searchValue.value = null;
    emit(
      'update:model-value',
      ((props.modelValue as Array<any>) || []).includes(getValue(item))
        ? (props.modelValue as Array<any>).filter((currentItem) => currentItem !== getValue(item))
        : [...((props.modelValue as Array<any>) || []), getValue(item)]
    );
  } else {
    searchValue.value = null;
    emit('update:model-value', getValue(item));
  }
};

const onClearSelectedItem = (index: number) => {
  if (!props.multiple) {
    return;
  }

  emit(
    'update:model-value',
    (props.modelValue as Array<any>).filter((_, itemIndex) => itemIndex !== index)
  );
};

onMounted(() => {
  isAutoCloseOnSelect.value = !props.multiple || !!props.autoCloseOnSelect;
});

watch(
  () => autoCompleteShown.value,
  (value) => nextTick(() => (isListShown.value = value)),
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _directive_z_index = _resolveDirective("z-index")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-select', { 'vz-select--loading': __props.loading, 'vz-select--disabled': __props.disabled }, `vz-select-${_unref(inputId)}`]),
    "data-errors": _unref(validateMessage)
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "vz-select__container",
      onClick: onClick
    }, [
      _renderSlot(_ctx.$slots, "prefix"),
      (!_ctx.$slots['badge'] && (vModel.value || []).length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!__props.loading)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass({ 'vz-select__container-multiple': __props.multiple, 'vz-select__container-select': !__props.multiple }),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (inputRef.value?.focus()))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vModel.value, (selected, index) => {
                    return _renderSlot(_ctx.$slots, _ctx.$slots['selection'] ? 'selection' : 'item', {
                      key: index,
                      item: selected
                    }, () => [
                      _createElementVNode("span", null, _toDisplayString(_unref(getTitle)(selected)), 1),
                      (index < vModel.value.length - 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, ","))
                        : _createCommentVNode("", true)
                    ])
                  }), 128))
                ], 2))
              : _createCommentVNode("", true),
            (vModel.value.length > 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "vz-select__container-multiple-count",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (inputRef.value?.focus()))
                }, "(" + _toDisplayString(vModel.value.length) + ")", 1))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        ref_key: "inputRef",
        ref: inputRef,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchValue).value = $event)),
        type: "text",
        tabindex: "0",
        readonly: __props.readonly,
        placeholder: !vModel.value?.length ? _ctx.$t(__props.placeholder) : undefined,
        disabled: __props.disabled,
        "aria-label": _unref(t)(`COMPONENT_LABELS.${__props.multiple ? 'AUTOCOMPLETE_FIELD' : 'SELECT_FIELD'}`, { value: __props.ariaLabel || __props.label || __props.placeholder }),
        onKeydown: onQuerySearch,
        onFocus: onFocus,
        onBlur: onBlur,
        onInput: onInput
      }, null, 40, _hoisted_4), [
        [_vModelText, searchValue.value]
      ]),
      (__props.clearable && isClearable.value)
        ? (_openBlock(), _createBlock(_component_vz_icon, {
            key: 1,
            role: "button",
            name: "svg:xmark",
            size: "0.75rem",
            color: "primary-900",
            clickable: !__props.disabled,
            "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' }),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:model-value', undefined)))
          }, null, 8, ["clickable", "aria-label"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append")
    ]),
    ((isListShown.value || isForceOpen.value) && !__props.loading)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "vz-select__list",
          style: _normalizeStyle({ top: `${top.value}px` })
        }, [
          _createElementVNode("div", {
            class: "vz-select__list-container",
            role: "list",
            style: _normalizeStyle({ position: 'fixed', width: width.value + 'px', maxHeight: `calc(${maxHeight.value}px - 3rem)` })
          }, [
            (__props.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('GENERAL.LOADING')), 1))
              : (!itemList.value?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t(__props.noDataText)), 1))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(itemList.value, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["vz-select__list-item", {
                [`vz-select__list-item-${index}`]: true,
                'vz-select__list-item--multiple': __props.multiple,
                'vz-select__list-item--active': selectedIndex.value === index,
                'vz-select__list-item--selected': __props.multiple && isItemSelected(item),
              }]),
                      role: "listitem",
                      onClick: ($event: any) => (onSelectFromList(item))
                    }, [
                      (!__props.hideCheckbox && __props.multiple)
                        ? (_openBlock(), _createBlock(_component_vz_icon, {
                            key: 0,
                            name: "svg:checkbox",
                            role: "checkbox",
                            "aria-label": _unref(t)('COMPONENT_LABELS.CHECKBOX', { value: __props.ariaLabel || __props.label })
                          }, null, 8, ["aria-label"]))
                        : _createCommentVNode("", true),
                      _renderSlot(_ctx.$slots, "item", {
                        item: item,
                        label: _unref(getTitle)(item)
                      }, () => [
                        _createElementVNode("span", null, _toDisplayString(_unref(getTitle)(item)), 1)
                      ])
                    ], 10, _hoisted_7))
                  }), 128))
          ], 4)
        ], 4)), [
          [_directive_z_index]
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(['vz-select__error', { 'vz-select__error--hidden': __props.hideDetails }]),
      role: "alert"
    }, [
      (_unref(validateMessage))
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-select__error-internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
        : (externalError.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t(externalError.value)), 1))
          : _createCommentVNode("", true)
    ], 2), [
      [_vShow, !_ctx.$slots['badge'] || _unref(validateMessage) || externalError.value]
    ]),
    (_ctx.$slots['badge'] && __props.multiple)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectionValueModal)(__props.modelValue), (item, index) => {
            return _renderSlot(_ctx.$slots, "badge", {
              key: index,
              item: item,
              text: item,
              value: _unref(getValue)(item),
              onClear: () => onClearSelectedItem(index)
            })
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})