const fitContent = (el: HTMLElement): void => {
  const width = el.parentElement?.offsetWidth;
  const height = el.parentElement?.offsetHeight;

  el.style.width = width ? width + 'px' : '100%';
  el.style.height = height ? height + 'px' : '100%';
};

export const vueFitContent = {
  mounted(el: HTMLElement) {
    fitContent(el);

    window.addEventListener('resize', () => fitContent(el));
  },
  updated(el: HTMLElement) {
    fitContent(el);
  },
  unmounted(el: HTMLElement) {
    window.removeEventListener('resize', () => fitContent(el));
  },
};
