// actions
export const LIST_COMPANIES = 'list-companies';
export const LIST_ADDRESSES = 'list-addresses';
export const GET_VIDEO_DETAILS = 'get-video-details';
export const LIST_CURRENCIES = 'list-currencies';
export const LIST_PHONE_CODES = 'list-phone-codes';
export const GET_ATTACHMENT = 'get-attachment';
export const GET_DATA = 'get-data';
export const CREATE_PDF = 'create-pdf';

// state
export const CURRENCIES = 'currencies';
export const PHONE_CODES = 'phone-codes';
export const COMPANIES_CACHE = 'companies';
export const ADDRESSES_CACHE = 'addresses-cache';

// mutation
export const SET_COMPANIES_CACHE = 'set-companies';
export const SET_ADDRESSES_CACHE = 'set-addresses';
export const SET_CURRENCIES = 'set-currencies';
export const SET_PHONE_CODES = 'set-phone-codes';
