import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "employee-strip__full-name" }
const _hoisted_2 = {
  key: 0,
  class: "text-ellipsis"
}
const _hoisted_3 = {
  key: 1,
  class: "text-ellipsis"
}
const _hoisted_4 = {
  key: 0,
  class: "text-subtitle-2"
}
const _hoisted_5 = { class: "employee-strip__group" }
const _hoisted_6 = { class: "d-flex gap-1" }
const _hoisted_7 = { class: "text-ellipsis" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "text-ellipsis text-subtitle-1"
}

import type { ProfileBaseDetails, ProfileExtendDetails, SearchEmployeeItem } from 'src/views/employee/types';
import type { GetConversationRes } from '@/views/job/types';
import { computed, type PropType, watch } from 'vue';
import { GET_EMPLOYEE } from '@/views/employee/store/employee.constants';
import { useAsync } from '@shared/composables';
import { SizeUnit } from '@shared/types';
import { useEmployeeStore } from '@/views/employee/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'employee-strip',
  props: {
  fontSize: { type: String as PropType<SizeUnit | `var(--font-size-${number})`>, default: 'var(--font-size-18)' },
  horizontal: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  item: {
    type: [Object, String] as PropType<
      ProfileExtendDetails | SearchEmployeeItem | (SearchEmployeeItem & { conversation?: GetConversationRes }) | undefined
    >,
    default: undefined,
  },
  active: { type: Boolean, default: false },
  avatarSize: { type: [String, Number], default: 32 },
  showCompany: { type: Boolean, default: false },
  hid: { type: String as PropType<string | undefined>, default: undefined },
},
  emits: ['select'],
  setup(__props) {

const props = __props;



const { [GET_EMPLOYEE]: getEmployeeAction } = useEmployeeStore();
const getEmployeeRequest = useAsync<ProfileBaseDetails>(getEmployeeAction as (userId: string) => Promise<ProfileBaseDetails>);

const employee = computed(() => {
  if (getEmployeeRequest.results.value) {
    return getEmployeeRequest.results.value;
  }

  return (props.item as SearchEmployeeItem)?.participants ? undefined : props.item;
});

const group = computed(() => ((props.item as SearchEmployeeItem)?.participants ? (props.item as SearchEmployeeItem) : undefined));
const groupHid = computed(() => (props.item as SearchEmployeeItem)?.participants?.slice(0, 3));
const groupMore = computed(() => ((props.item as SearchEmployeeItem)?.participants?.length || 0) - (groupHid.value?.length || 0));

watch(
  () => props.hid,
  (hid) => {
    if (hid) {
      getEmployeeRequest.call(hid);
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_avatar = _resolveComponent("vz-avatar")!

  return (employee.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['employee-strip', { clickable: __props.clickable, active: __props.active, skeleton: __props.loading || _unref(getEmployeeRequest).loading.value }]),
        style: _normalizeStyle({ fontSize: __props.fontSize }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', employee.value)))
      }, [
        _createVNode(_component_vz_avatar, {
          hid: __props.hid || employee.value._id,
          "first-name": employee.value.firstName,
          "last-name": employee.value.lastName,
          size: __props.avatarSize,
          verified: employee.value.isVerified
        }, null, 8, ["hid", "first-name", "last-name", "size", "verified"]),
        _createElementVNode("div", {
          class: _normalizeClass(['d-flex', { 'flex-column': __props.horizontal, 'gap-2': !__props.horizontal }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            (employee.value.firstName && employee.value.lastName)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString([employee.value.firstName, employee.value.lastName].join(' ')), 1))
              : _createCommentVNode("", true),
            (employee.value.email)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(employee.value.email), 1))
              : _createCommentVNode("", true)
          ]),
          (__props.showCompany)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(employee.value.company), 1))
            : _createCommentVNode("", true)
        ], 2)
      ], 6))
    : (group.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["employee-strip", { clickable: __props.clickable, active: __props.active, skeleton: __props.loading }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select', group.value)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupHid.value, (gHid, index) => {
            return (_openBlock(), _createBlock(_component_vz_avatar, {
              key: index,
              hid: gHid,
              size: __props.avatarSize
            }, null, 8, ["hid", "size"]))
          }), 128)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(group.value.title), 1),
              (groupMore.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(+" + _toDisplayString(groupMore.value) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            (group.value?.description)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(group.value?.description), 1))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
}
}

})