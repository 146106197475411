<template>
  <div
    ref="elementRef"
    v-visibility="{ onVisible, onHidden }"
    :class="['message-card', { 'message-card--me': isMe(message.fromId) && !disabled, [`message-card--${message.key}`]: message.key }]"
  >
    <div class="message-card__header" v-on="!isMe(message.fromId) ? { click: () => $emit('select:participant', message.fromId) } : {}">
      <vz-avatar
        :hid="user?._id"
        :first-name="user?.firstName"
        :last-name="user?.lastName"
        :color="isOnline ? 'green-500' : undefined"
        :size="avatarSize"
      />

      <div class="message-card__header-sender">
        <div>
          <span>{{ user?.firstName }}</span>
          <span>{{ user?.lastName }}</span>
          <span>{{ user?.nickName }}</span>
        </div>

        <p v-if="message.updatedAt">{{ timeFromNow(message.updatedAt) }}</p>

        <p v-if="status">{{ $t(status) }}</p>
      </div>
    </div>

    <div class="message-card__content">
      <div>
        <message-card
          v-if="message.replyTo"
          class="border-regular border-radius-medium pa-2 bg-mono-200 mb-2"
          disabled
          avatar-size="24"
          :online="online"
          :participants="participants"
          :message="message.replyTo"
        />
      </div>

      <vz-attachment
        v-if="message.attachments?.length"
        readonly
        accept-type="image/jpeg,image/png,application/pdf"
        :value="message.attachments"
        :provider="getAttachment"
      />

      <message-share-location v-if="message.coordinates" :coordinates="message.coordinates" />

      <div v-if="htmlMessage" :style="style" v-html="htmlMessage" />

      <vz-audio v-if="message.audioId" ref="audioPlayerRef" :hid="message.audioId" @end="$emit('audio:playback-end')" />

      <div v-if="isMe(user?._id)" class="message-card__content-state">
        <vz-icon v-if="isSent" name="svg:check" size="12px" :color="isSent === 'SOME' ? 'primary-300' : 'green-400'" />
        <vz-icon v-if="isRead" name="svg:check" size="12px" :color="isRead === 'SOME' ? 'primary-300' : 'green-400'" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from 'vue';
import type { BaseMessage } from '@/views/messenger/types';
import type { BaseChat } from '@/views/messenger/types';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { timeFromNow } from '@/views/calendar/helpers';
import { linkify } from '@shared/helpers/linkify';
import emojiRegex from 'emoji-regex';
import { useSwipe } from '@shared/composables/use-swipe';
import { isRtl } from '@/plugins/i18n/helpers';
import VzAttachment from '@shared/components/vz-attachment.vue';
import { GET_ATTACHMENT } from '@/store/client/client.constants';
import MessageShareLocation from '@shared/components/messenger/components/message-share-sheet/message-share-location.vue';
import { useGeneralStore } from '@/store/client';

const props = defineProps({
  disabled: { type: Boolean, default: false },
  message: { type: Object as PropType<BaseMessage>, required: true },
  participants: { type: Array as PropType<BaseChat['participants']>, required: true },
  online: { type: Array as PropType<Array<string>>, default: () => [] },
  status: { type: String as PropType<string | undefined>, default: undefined },
  avatarSize: { type: [Number, String], default: 32 },
});

const emit = defineEmits(['visible', 'hidden', 'audio:playback-end', 'reply:message', 'select:participant']);

const { [GET_ATTACHMENT]: getAttachment } = useGeneralStore();

const { isMe, myId } = useAuthUser();

const audioPlayerRef = ref();
const elementRef = ref<HTMLElement | undefined>(undefined);

useSwipe(elementRef, {
  isRtl: ref(isRtl()),
  minStepSize: 80,
  disabled: computed(() => props.disabled),
  onNext: () => emit('reply:message', props.message),
  onBack: () => emit('reply:message', props.message),
});

const hasRead = computed(() => !!(props.message?.readBy || {})[myId.value]);

const onVisible = () => {
  if (hasRead.value) {
    return;
  }

  emit('visible', props.message);
};

const onHidden = () => {
  if (hasRead.value) {
    return;
  }

  emit('hidden', props.message);
};

const isRead = computed((): 'SOME' | 'EVERY' | false => {
  const readBy = Object.keys(props.message?.readBy || {});
  const participantsIds = props.participants?.filter(({ _id }) => !isMe(_id)).map(({ _id }) => _id);

  if (!readBy.length) {
    return false;
  }

  if (participantsIds?.every((id) => readBy.includes(id))) {
    return 'EVERY';
  } else if (participantsIds?.some((id) => readBy.includes(id))) {
    return 'SOME';
  }

  return false;
});

const isSent = computed((): 'SOME' | 'EVERY' | false => {
  const sentTo = [...new Set([...Object.keys(props.message?.sentTo || {}), ...Object.keys(props.message?.readBy || {})])];
  const participantsIds = props.participants?.filter(({ _id }) => !isMe(_id)).map(({ _id }) => _id);

  if (!sentTo.length) {
    return false;
  }

  if (participantsIds.every((id) => sentTo.includes(id))) {
    return 'EVERY';
  } else if (participantsIds.some((id) => sentTo.includes(id))) {
    return 'SOME';
  }

  return false;
});

const htmlMessage = computed(() => {
  if (!props.message?.message) {
    return null;
  }

  return linkify(props.message.message).replace(/(\r\n|\r|\n)/g, '<br>');
});

const style = computed((): Record<string, string> => {
  const match = props.message?.message?.match(emojiRegex());

  return match?.length === 1 && match[0] === props.message?.message ? { fontSize: '2.5rem', textAlign: 'center' } : {};
});

const user = computed(() => (props.participants || []).find(({ _id }) => _id === props.message?.fromId));
const isOnline = computed(() => (user.value ? props.online?.includes(user.value._id) : false));

defineExpose({ play: () => audioPlayerRef.value?.play() });
</script>

<style scoped lang="scss">
.message-card {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &--me {
    > * {
      text-align: end;
      flex-direction: row-reverse;
      justify-content: flex-start;
      width: 100%;
    }
  }

  &__header {
    display: flex;

    &:not(&-me) {
      cursor: pointer;
    }

    &-sender {
      margin: 0 0.25rem;
      display: flex;
      flex-direction: column;

      > div:first-child {
        font-weight: 500;

        span {
          padding: 0 2px;
        }
      }

      > p:last-child {
        font-size: var(--font-size-14);
      }
    }
  }

  &__content {
    position: relative;
    margin: 0 1rem;
    padding: 0.5rem 0.5rem 0.75rem 0.5rem;
    background-color: var(--color-primary-100);
    max-width: fit-content;
    user-select: none;
    @include rtl(border-radius, 0.5rem 0 0.5rem 0.5rem, 0 0.5rem 0.5rem 0.5rem);

    * {
      word-wrap: break-word;
    }

    .message-card__content {
      width: 100%;
      background-color: transparent !important;
      margin: 0;
      padding-top: 0.25rem;
      padding-bottom: 0;
    }

    .message-card--me & {
      align-self: end;
      @include rtl(border-radius, 0 0.5rem 0.5rem 0.5rem, 0.5rem 0 0.5rem 0.5rem);
      background-color: var(--color-primary-100);
    }

    &-state {
      display: flex;
      color: var(--color-primary-400);
      position: absolute;
      bottom: 2px;
      @include inline-start(0.25rem);

      > * {
        border-radius: 50%;
        padding: 2px;
        margin-inline-end: -12px;
      }
    }
  }
}
</style>
