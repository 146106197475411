import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "fill-width border-top-medium" }

import { computed, nextTick, onUnmounted, type PropType, ref, watch } from 'vue';
import type { SendMessagePayload } from '@shared/components/messenger/messenger.type';
import type { BaseChat, BaseMessage } from '@/views/messenger/types';
import type { BaseId, BaseRecords } from '@shared/models';
import type { SocketReceivedMessage } from '@shared/services/socket-service/socket-client.types';
import type { FileDropEvent } from '@shared/elements/file-drop';
import type { VzInfinityScrollRef } from '@shared/components/infinity-scroll/infinity-scroll.type';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import MessageCard from '@shared/components/messenger/components/message-card.vue';
import SocketClientService from '@shared/services/socket-service/socket-client.service';
import { SEND_MESSAGE } from '@/views/messenger/store/messenger.constants';
import { SocketEnum } from '@shared/services/socket-service/socket.enum';
import ProcessService from '@shared/services/process.service';
import MessageTyping from '@shared/components/messenger/components/message-typing.vue';
import { useAsync } from '@shared/composables';
import { LENGTH } from '@shared/constants/length';
import MessageInput from '@shared/components/messenger/components/message-input.vue';
import { useFirebasePush } from '@shared/composables/use-firebase-push';
import VzCard from '@shared/components/vz-card.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-messenger',
  props: {
  roomKey: { type: String as PropType<'messenger' | 'job-manager-conversation'>, required: true },
  chatId: { type: String as PropType<BaseId | undefined>, required: true },
  chatIdKey: { type: String, required: true },
  participants: { type: Array as PropType<BaseChat['participants']>, required: true },
  maxLength: { type: [String, Number], default: LENGTH.LONG_DESCRIPTION },
  disabled: { type: Boolean, default: false },
  messageIdKey: { type: String, default: 'key' },
  getMessagesCallback: { type: Function as PropType<(...arg: any) => Promise<BaseRecords<BaseMessage>>>, required: true },
  sendMessageCallback: { type: Function as PropType<(...arg: any) => Promise<BaseMessage>>, required: true },
},
  emits: ['select:participant', 'visible:message', 'hidden:message', 'recent:message', 'user:typing'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const payload = ref<SendMessagePayload>({} as SendMessagePayload);
const sendMessageRequest = useAsync<BaseMessage>(props.sendMessageCallback as (payload: any) => Promise<BaseMessage>, { errorsCleanTimeout: 3000 });

const $patchPayload = (state: Partial<SendMessagePayload>) => {
  payload.value = { ...payload.value, ...state };
};

const emit = __emit;

const { isMe, myId } = useAuthUser();

const inputRef = ref();
const infinityScrollRef = ref<VzInfinityScrollRef>(undefined);
const onlineClients = ref<Array<string>>([]);
const typingClients = ref<Array<string>>([]);

const userTyping = computed(() => props.participants?.find(({ _id }) => _id === typingClients.value.find((id) => !isMe(id))));
const isSending = computed(() => sendMessageRequest.loading.value);

const onMessagesClick = (): void => {
  $patchPayload({ coordinates: undefined, attachments: undefined });
};

const onFileDragAndDrop = ({ detail }: CustomEvent<FileDropEvent>): void => {
  if (props.disabled) {
    return;
  }

  const attachments = [...(payload.value.attachments || []), ...Array.from(detail.files || [])];
  $patchPayload({ attachments });
};

const onReplyMessage = (message: BaseMessage): void => {
  $patchPayload({ replyTo: message });
  nextTick(goToEnd);
};

const onSendMessage = async (state: SendMessagePayload = {}): Promise<void> => {
  const data = { ...payload.value, ...state };
  payload.value = {};
  await sendMessageRequest.call({ [props.chatIdKey]: props.chatId, ...(data || {}) });

  if (!sendMessageRequest.results.value) {
    return;
  }

  infinityScrollRef.value?.push(sendMessageRequest.results.value, { idKey: 'key' });
  goToEnd();
};

const onMessageVisible = (message: BaseMessage): void => {
  SocketClientService.send<{ userId: string; key: string }>(SocketEnum.SEEN, `${props.chatId}:${props.roomKey}`, {
    userId: myId.value,
    key: message.key,
  });

  emit('visible:message', message);
};

const onMessageHidden = (message: BaseMessage): void => {
  emit('hidden:message', message);
};

const goToEnd = (): void => {
  nextTick(() => infinityScrollRef.value?.scrollTo('END'));
};

const initSocket = (): void => {
  if (!props.chatId || !props.roomKey) {
    return;
  }

  SocketClientService.join({ roomId: props.chatId, roomKey: props.roomKey });

  SocketClientService.on(SocketEnum.ONLINE, (online: Array<string>) => {
    onlineClients.value = online;
  });

  SocketClientService.on(
    SEND_MESSAGE,
    (message: BaseMessage) => {
      infinityScrollRef.value?.push(message, { idKey: 'key' });
      emit('recent:message', message);
    },
    true
  );

  SocketClientService.on(SocketEnum.TYPING, (isTyping: boolean, { fromId }: SocketReceivedMessage) => {
    if (isTyping && !typingClients.value.includes(fromId)) {
      typingClients.value = [...new Set([fromId, ...typingClients.value])];
      goToEnd();
    } else if (!isTyping && typingClients.value.includes(fromId)) {
      typingClients.value = typingClients.value.filter((uid) => uid !== fromId);
    }
  });

  SocketClientService.on(SocketEnum.SEEN, ({ key, readBy }: Partial<BaseMessage>) => {
    const item: BaseMessage = infinityScrollRef.value?.items.find((item: Record<string, any>) => item.key === key);
    const update = { ...(item || {}), readBy: { ...(item?.readBy || {}), ...(readBy || {}) } };
    infinityScrollRef.value?.update(update, 'key');
  });

  SocketClientService.on(SocketEnum.PUSH, ({ key, sentTo }: Partial<BaseMessage>) => {
    const item: BaseMessage = infinityScrollRef.value?.items.find((item: Record<string, any>) => item.key === key);
    const update = { ...(item || {}), sentTo: { [myId.value]: Date.now(), ...(sentTo || {}) } };
    infinityScrollRef.value?.update(update, 'key');
  });

  nextTick(() => infinityScrollRef.value?.reset());
};

const onTyping = (): void => {
  if (!typingClients.value.includes(myId.value) && payload.value.message) {
    SocketClientService.send<boolean>(SocketEnum.TYPING, `${props.chatId}:${props.roomKey}`, true);
  } else if (!payload.value.message) {
    SocketClientService.send<boolean>(SocketEnum.TYPING, `${props.chatId}:${props.roomKey}`, false);

    return;
  }

  ProcessService.debounce(
    () => {
      SocketClientService.send<boolean>(SocketEnum.TYPING, `${props.chatId}:${props.roomKey}`, false);
    },
    props.chatId,
    1000
  );
};

watch(() => payload.value.message, onTyping);
watch(() => props.disabled, goToEnd);

watch(
  () => typingClients.value,
  () => {
    emit('user:typing', props.participants?.find(({ _id }) => _id === typingClients.value[0]));
  }
);

watch(
  () => [props.chatId, props.roomKey],
  ([newChatId, _, oldChatId, oldChatType]) => {
    if (oldChatId) {
      SocketClientService.leave({ roomId: oldChatId, roomKey: oldChatType });
    }

    if (!newChatId) {
      return;
    }

    initSocket();
  },
  { immediate: true }
);

onUnmounted(() => {
  if (props.chatId && props.roomKey) {
    SocketClientService.leave({ roomId: props.chatId, roomKey: props.roomKey });
  }
});

useFirebasePush(
  ({ data }: { data: BaseMessage }) => {
    infinityScrollRef.value?.push(data, { idKey: 'key' });
  },
  { type: SEND_MESSAGE, id: props.chatId }
);

__expose({ push: (item: BaseMessage) => infinityScrollRef.value?.push(item, { idKey: props.messageIdKey }) });

return (_ctx: any,_cache: any) => {
  const _component_vz_error_alert = _resolveComponent("vz-error-alert")!
  const _component_vz_infinity_scroll = _resolveComponent("vz-infinity-scroll")!
  const _component_file_drop = _resolveComponent("file-drop")!

  return (_openBlock(), _createBlock(VzCard, {
    class: "d-flex flex-column flex-grow-1",
    "non-clickable": "",
    flat: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_file_drop, {
        class: "messenger fill-width flex-grow-1",
        accept: "image/jpeg,image/png,application/pdf",
        onUpdate: onFileDragAndDrop
      }, {
        default: _withCtx(() => [
          _createVNode(_component_vz_infinity_scroll, {
            ref_key: "infinityScrollRef",
            ref: infinityScrollRef,
            class: _normalizeClass(['messenger__content', { 'messenger__content--disabled': __props.disabled }]),
            "hide-empty-state": "",
            "hide-first-load": "",
            "disable-payload-watcher": "",
            reverse: "",
            callback: __props.getMessagesCallback,
            payload: { [__props.chatIdKey]: __props.chatId },
            onClick: onMessagesClick
          }, _createSlots({
            default: _withCtx(({ data }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (message, index) => {
                return (_openBlock(), _createBlock(MessageCard, {
                  key: index,
                  message: message,
                  participants: __props.participants,
                  online: onlineClients.value,
                  onVisible: onMessageVisible,
                  onHidden: onMessageHidden,
                  "onReply:message": onReplyMessage,
                  "onSelect:participant": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select:participant', $event)))
                }, null, 8, ["message", "participants", "online"]))
              }), 128)),
              (userTyping.value)
                ? (_openBlock(), _createBlock(MessageTyping, {
                    key: 0,
                    online: onlineClients.value,
                    user: userTyping.value
                  }, null, 8, ["online", "user"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, [
            (_unref(sendMessageRequest).error.value)
              ? {
                  name: "header",
                  fn: _withCtx(() => [
                    _createVNode(_component_vz_error_alert, {
                      error: _unref(sendMessageRequest).error.value
                    }, null, 8, ["error"])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["class", "callback", "payload"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MessageInput, {
          ref_key: "inputRef",
          ref: inputRef,
          class: "min-height-64",
          loading: isSending.value,
          value: payload.value,
          participants: __props.participants,
          onSend: onSendMessage,
          onForce: goToEnd,
          onBlur: goToEnd,
          "onUpdate:payload": $patchPayload
        }, null, 8, ["loading", "value", "participants"])
      ])
    ]),
    _: 1
  }))
}
}

})