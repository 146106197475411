import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import type { GetNotificationReq, GetNotificationsRes } from '@/views/notifications/store/notification.types';
import NotificationService from '@/views/notifications/store/service/notifications.service';

const notificationService = container.resolve(NotificationService);

export const useNotificationStore = defineStore(StoreNamespace.NOTIFICATION_MODULE, () => {
  return {
    [GET_NOTIFICATIONS]: async (payload: GetNotificationReq): Promise<GetNotificationsRes> => {
      return await notificationService[GET_NOTIFICATIONS](payload);
    },
  };
});
