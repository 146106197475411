import { computed, type ComputedRef } from 'vue';
import { numberWithCommas } from '@shared/helpers/number-with-commas';
import { CURRENCIES } from '@/store/client/client.constants';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { storeToRefs } from 'pinia';
import { useGeneralStore } from '@/store/client';

export const useCurrencySymbol = (amount?: number, currencyCode?: string): ComputedRef<string> => {
  if (!amount) {
    return computed(() => '-');
  }

  const { [CURRENCIES]: currencies } = storeToRefs(useGeneralStore());

  const currency = computed((): string | undefined => currencyCode || useAuthUser().activeUser.value?.currency);

  return computed(() => {
    if (!currency.value) {
      return numberWithCommas(amount);
    }

    const { symbol } = currencies.value?.find(({ code }) => code === currency.value) || {};

    return symbol ? [numberWithCommas(amount), symbol].join('') : numberWithCommas(amount);
  });
};
