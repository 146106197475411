import { type DirectiveBinding, watch } from 'vue';
import { getLastZIndex } from '@shared/helpers';

export const vueZIndex = {
  mounted(el: HTMLElement, binding: DirectiveBinding<boolean>) {
    watch(
      () => binding.value,
      () => {
        const zIndex = getLastZIndex() + 1000;

        el.style.zIndex = zIndex.toString();
      },
      { immediate: true }
    );
  },
};
