<template>
  <vz-page-wrapper :modules="authModules" :show-side-bar="!!assignedModulesState?.userId">
    <template #header="{ isShown }">
      <vz-avatar v-if="assignedModulesState?.userId && !isShown" class="ms-4" size="32" :hid="assignedModulesState?.userId" />
      <vz-button v-else class="ms-4 pa-1" type="rounded" :icon-name="!isShown ? 'svg:menu' : 'svg:arrow-up'" icon-size="1.125rem" />
    </template>

    <template #menu>
      <manager-assignee-select />
    </template>

    <router-view v-slot="{ Component, route }">
      <component :is="Component" :key="route.path" />
    </router-view>
  </vz-page-wrapper>
</template>

<script setup lang="ts">
import VzPageWrapper from '@shared/components/vz-page-wrapper.vue';
import { computed } from 'vue';
import { ASSIGNED } from '@/store/auth/auth.constants';
import ManagerAssigneeSelect from '@/components/fields/manager-assignee-select.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';

const { [ASSIGNED]: assignedModulesState } = storeToRefs(useAuthStore());

const authModules = computed(() =>
  assignedModulesState.value
    ? Object.entries(assignedModulesState.value.modules)
        .filter(([_, value]) => value.length)
        .map(([key]) => key)
    : null
);
</script>
