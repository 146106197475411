import type { ItemTextReturn, ItemValueReturn } from '../models';
import type { Ref } from 'vue';

export const useItemValuesRevert = (
  items: Ref<Array<any>>,
  multiple: boolean,
  getValue: ItemValueReturn,
  getTitle: ItemTextReturn = (value) => value,
  itemIdKey?: string
): ((value?: any) => Array<any>) => {
  return (value?: any) => {
    if (!value) {
      return null;
    }

    return (multiple ? value : [value])
      .map((itemValue: any) => {
        return (items.value || []).find((item) => {
          const currentValue = getValue(item);

          return itemIdKey ? currentValue[itemIdKey] === itemValue[itemIdKey] : JSON.stringify(itemValue) === JSON.stringify(currentValue);
        });
      })
      .map((item: any) => getTitle(item) || item);
  };
};
