type Options = Partial<{ immediate: boolean; async: boolean; defer: boolean; root: HTMLElement }>;

export default async (url: string, options?: Options): Promise<{ load: () => Promise<void>; unload: () => void }> => {
  const { immediate = true, async = true, defer = true, root = document.head } = options || {};

  const load = async (): Promise<void> => {
    if (document.querySelector(`script[src="${url}"]`)) {
      return;
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = async;
      script.defer = defer;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${url}`));

      root.appendChild(script);
    });
  };

  const unload = (): void => {
    root.querySelector(`script[src="${url}"]`)?.remove();
  };

  if (immediate) {
    await load();
  }

  return { load, unload };
};
