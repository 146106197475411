import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 1,
  class: "vz-badge__prefix"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "vz-badge__prefix"
}
const _hoisted_5 = {
  key: 3,
  class: "vz-badge__counter"
}
const _hoisted_6 = {
  key: 4,
  class: "vz-badge__text"
}

import { computed, type PropType } from 'vue';
import type { ColorsMap } from '@/shared/services/css-service/types/colors';
import type { SizeUnit } from '@shared/types';
import { GlobalVariables } from '@/shared/constants/global-variables';
import dayjs from 'dayjs';
import getThemeColor from '@/shared/services/css-service/helpers/get-theme-color';
import { useTranslator } from '@/plugins/i18n/helpers';
import { IconName } from '@shared/components/icon/icon.type';
import VzIcon from '@shared/components/icon/vz-icon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-badge',
  props: {
  loading: { type: Boolean, default: false },
  fit: { type: Boolean, default: false },
  text: { type: String, default: '' },
  date: { type: Number, default: undefined },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  counter: { type: Number, default: undefined },
  prefix: { type: String as PropType<string>, default: undefined },
  iconName: { type: String as PropType<IconName>, default: undefined },
  iconSize: { type: String as PropType<SizeUnit>, default: '1rem' },
  clearable: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  selected: { type: Boolean, default: false },
  outlined: { type: Boolean, default: false },
  multiline: { type: Boolean, default: false },
  fontSize: { type: String as PropType<SizeUnit | `var(--font-size-${number})`>, default: '1rem' },
  fontWeight: {
    type: [String, Number] as PropType<`var(--font-weight-${'regular' | 'semibold' | 'bold'})`>,
    default: 'var(--font-weight-semibold)',
  },
  transparency: { type: Boolean, default: false },
},
  emits: ['clear'],
  setup(__props) {



const props = __props;

const t = useTranslator();

const getDate = computed((): string | undefined => (props.date ? dayjs(props.date).format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT) : undefined));
const getColor = computed((): string | undefined => (props.color ? getThemeColor(props.color) : undefined));
const areaLabel = computed((): string => [props.counter, props.date, props.text].filter((value) => value !== undefined).join(' '));

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'vz-badge',
      {
        'vz-badge--clickable': __props.clickable,
        'vz-badge--selected': __props.selected,
        'vz-badge--outlined': __props.outlined,
        'vz-badge--multiline': __props.multiline,
        'vz-badge--transparency': __props.transparency,
        'skeleton--blur': __props.loading,
      },
    ]),
    style: _normalizeStyle({ color: getColor.value, fontSize: __props.fontSize, fontWeight: __props.fontWeight }),
    "aria-label": _unref(t)('COMPONENT_LABELS.BADGE', { value: areaLabel.value || __props.text })
  }, [
    (__props.clearable)
      ? (_openBlock(), _createBlock(_component_vz_button, {
          key: 0,
          type: "flat",
          "icon-size": "1rem",
          "icon-color": __props.color,
          "icon-name": "svg:xmark",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clear')))
        }, null, 8, ["icon-color"]))
      : _createCommentVNode("", true),
    (__props.prefix || __props.iconName || _ctx.$slots['prefix'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "prefix"),
          (__props.iconName)
            ? (_openBlock(), _createBlock(VzIcon, {
                key: 0,
                name: __props.iconName,
                size: __props.iconSize
              }, null, 8, ["name", "size"]))
            : _createCommentVNode("", true),
          (__props.prefix)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(__props.prefix)), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (getDate.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t(__props.text)), 1))
      : _createCommentVNode("", true),
    (__props.counter !== undefined)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.counter), 1))
      : _createCommentVNode("", true),
    (__props.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(getDate.value || _ctx.$t(__props.text)), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 14, _hoisted_1))
}
}

})