import scriptCdn from '@/shared/helpers/script-cdn';

const CDN_URL = 'https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js';
const HEADER_HEIGHT_PERCENTAGE = 0.8;
const FOOTER_HEIGHT_PERCENTAGE = 0.8;

interface HtmlToPdfOptions {
  header?: string | Promise<string>;
  footer?: string | Promise<string>;
  margin?: number | [number, number, number, number];
  image?: { type: string; quality: number };
  html2canvas?: { scale: number };
  jsPDF?: { unit: string; format: string; orientation: string };
}

const loadImg = async (data?: string | Promise<string>): Promise<HTMLImageElement | null> => {
  if (!data) {
    return null;
  }

  const base64 = data instanceof Promise ? await data : data;

  return new Promise((resolve) => {
    const img = new Image();

    img.src = base64;
    img.onload = () => resolve(img);
    img.onerror = () => resolve(null);
  });
};

export async function htmlToPdf(html: HTMLElement | string, options?: HtmlToPdfOptions): Promise<Blob> {
  const { unload } = await scriptCdn(CDN_URL);

  const html2pdf = (window as any).html2pdf;

  if (!html2pdf) {
    throw new Error('html2pdf.js library is not loaded.');
  }

  const container = document.createElement('html');
  container.innerHTML = html instanceof HTMLElement ? html.outerHTML : html;
  const dir: 'ltr' | 'rtl' = (container.querySelector('[dir]')?.getAttribute('dir') || 'ltr') as 'ltr' | 'rtl';

  const headerImg = await loadImg(options?.header);
  const footerImg = await loadImg(options?.footer);

  const {
    margin = [headerImg ? 3 : 1, 1, footerImg ? 3 : 1, 1], // Default margins
    image = { type: 'jpeg', quality: 0.98 },
    html2canvas = { scale: 2 },
    jsPDF = { unit: 'cm', format: 'a4', orientation: 'portrait' },
  } = options || {};

  const [marginTop, marginRight, marginBottom, marginLeft] = Array.isArray(margin) ? margin : [margin, margin, margin, margin];

  try {
    return new Promise((resolve, reject) => {
      html2pdf()
        .set({
          margin: [marginTop, marginRight, marginBottom, marginLeft],
          image,
          html2canvas,
          jsPDF,
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        })
        .from(container)
        .toPdf()
        .get('pdf')
        .then((jsPdfInstance: any) => {
          const totalPages = jsPdfInstance.internal.getNumberOfPages();
          const pageWidth = jsPdfInstance.internal.pageSize.getWidth();
          const pageHeight = jsPdfInstance.internal.pageSize.getHeight();

          for (let i = 1; i <= totalPages; i++) {
            jsPdfInstance.setPage(i);

            // Add header
            if (headerImg) {
              const headerHeight = marginTop * HEADER_HEIGHT_PERCENTAGE;
              const imgAspectRatio = headerImg.width / headerImg.height;
              const headerWidth = pageWidth;
              const headerHeightAdjusted = Math.min(headerHeight, headerWidth / imgAspectRatio);

              jsPdfInstance.addImage(headerImg, 'JPEG', 0, marginTop * ((1 - HEADER_HEIGHT_PERCENTAGE) / 2), headerWidth, headerHeightAdjusted);
            }

            // Add footer
            if (footerImg) {
              const footerHeight = marginBottom * FOOTER_HEIGHT_PERCENTAGE;
              const imgAspectRatio = footerImg.width / footerImg.height;
              const footerWidth = pageWidth;
              const footerHeightAdjusted = Math.min(footerHeight, footerWidth / imgAspectRatio);

              jsPdfInstance.addImage(
                footerImg,
                'JPEG',
                0,
                pageHeight - footerHeightAdjusted - marginBottom * ((1 - FOOTER_HEIGHT_PERCENTAGE) / 2),
                footerWidth,
                footerHeightAdjusted
              );
            }
          }

          const blob = jsPdfInstance.output('blob');

          resolve(blob);
        })
        .catch(reject);
    });
  } finally {
    unload();
  }
}
