export const GlobalVariables = {
  US_DATE: 'YYYY MM DD',
  DAY_MONTH_YEAR_FORMAT: 'DD/MM/YYYY',
  SHORT_DAY_MONTH_YEAR_FORMAT: 'DD/MM/YY',
  DAY_MONTH_YEAR_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
  EU_LONG_DATE_FORMAT: 'DD MMM YYYY',
  TIME_FORMAT: 'HH:mm',
  WEEK_DAY_MONTH_YEAR_FORMAT: 'DD/MM/YYYY dddd',
  FULL_DATE_AND_TIME: 'dddd DD MMM YYYY, HH:mm',
  FULL_DATE: 'dddd, DD MMM YYYY',
  FULL_DATE_WITHOUT_DAY: 'DD MMM YYYY',
};
