<template>
  <div v-if="!route.meta.hideMenu" class="vz-page-wrapper">
    <div v-if="activeItem && showSideNavbar" class="vz-page-wrapper__minimal" role="button" @click="isShown = !isShown">
      <slot name="header" :is-shown="isShown" />

      <div class="vz-page-wrapper__menu-item pa-0">
        <span v-if="activeItem?.label">{{ $t(activeItem.label) }}</span>
      </div>
    </div>

    <div v-if="showSideNavbar" :class="['vz-page-wrapper__menu', { 'vz-page-wrapper__menu--hidden': !isShown }]">
      <div class="px-2">
        <slot name="menu" />
      </div>

      <div
        v-for="(item, index) in items"
        :key="index"
        :class="['vz-page-wrapper__menu-item', { 'vz-page-wrapper__menu-item--active': item.name === route.name }]"
        @click="onClick(item)"
      >
        <vz-icon v-if="item.icon" size="1.75rem" :name="item.icon" />

        <span v-if="item.label">{{ $t(item.label) }}</span>
      </div>
    </div>

    <div class="vz-page-wrapper__content">
      <slot />
    </div>
  </div>

  <slot v-else />
</template>

<script setup lang="ts">
import type { RouteItem } from '@/router/models/route-item';
import { computed, PropType, ref } from 'vue';
import { useMenuItems } from '@/router/helpers';
import { useRoute } from 'vue-router';
import { isMobile } from '@shared/helpers';
import { routeTo } from '@shared/composables';

const route = useRoute();

const props = defineProps({
  modules: { type: Array as PropType<Array<string> | null>, default: null },
  showSideBar: { type: Boolean, default: false },
});

const isShown = ref<boolean>(!isMobile);

const activeItem = computed(() => items.value.find((item) => item.name === route.name));

const items = useMenuItems({
  routeName: computed(() => route.meta.parentName as string),
  authModules: computed(() => props.modules),
});

const showSideNavbar = computed(() => {
  return (items.value?.length && items.value.length > 1) || props.showSideBar;
});

const onClick = (item: RouteItem) => {
  routeTo({ name: item.name }, { isOverride: true });

  isShown.value = false;
};
</script>

<style scoped lang="scss">
.vz-page-wrapper {
  position: relative;
  display: flex;
  height: var(--content-height);
  background-color: var(--color-background-active);
  overflow: hidden;

  @include max-sm-layout {
    flex-direction: column;
  }

  > :last-child {
    flex-grow: 1;
    overflow: hidden;

    @include min-sm-layout {
      padding: 0 0.5rem;
      margin: 1rem;
    }
  }

  &__minimal {
    color: var(--color-primary-100);
    background: var(--color-primary-900);
    box-shadow: var(--shadow-hard);
    width: calc(100% - 0.5rem);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: var(--font-weight-semibold);
    padding: 0.25rem 0.75rem;
    margin: 0 0.25rem;

    > button {
      margin: 0.25rem;

      @include rtl(transform, scaleX(-1));
    }

    @include min-sm-layout {
      display: none;
    }
  }

  &__menu {
    @include min-sm-layout {
      min-width: 360px;
      max-width: 360px;
    }

    @include max-sm-layout {
      min-width: 100%;

      &--hidden {
        display: none;
      }
    }
    padding-top: calc((var(--header-height) + var(--footer-height)));
    padding-bottom: calc((var(--header-height) + var(--footer-height)));
    margin: calc(-1 * (var(--header-height) + var(--footer-height))) 0;
    background: var(--color-mono-100);
    box-shadow: var(--shadow-light);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-item {
      cursor: pointer;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: background 0.2s ease-in-out;
      text-transform: uppercase;
      font-weight: var(--font-weight-regular);

      &:hover:not(&--active) {
        background: var(--color-primary-200);
      }

      &--active {
        background: var(--color-primary-900);
        color: var(--color-primary-100);
        font-weight: 600;
      }
    }
  }
}
</style>
