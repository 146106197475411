import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-title-1"
}
const _hoisted_2 = ["href"]

import { computed, type PropType } from 'vue';
import type { RouteLocation } from 'vue-router';
import VzButton from '@shared/components/buttons/vz-button.vue';
import { routeTo, useCurrencySymbol } from '@shared/composables';
import { numberWithCommas } from '@shared/helpers';
import { phonePrettier } from '@shared/helpers/phone-prettier';
import { BasePhone } from '@shared/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-field',
  props: {
  hideEmpty: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  ltr: { type: Boolean, default: false },
  textEllipsis: { type: Boolean, default: false },
  value: {
    type: [String, Array, Object] as PropType<BasePhone | string | number | boolean | Array<string | number | boolean> | null | undefined>,
    default: undefined,
  },
  label: { type: String as PropType<string | undefined>, default: undefined },
  type: { type: String as PropType<'phone' | 'email' | 'currency'>, default: undefined },
  refer: { type: Object as PropType<Partial<Pick<RouteLocation, 'name' | 'path' | 'params' | 'query'>> | undefined>, default: undefined },
},
  setup(__props) {

const props = __props;

const isNumber = computed(() => typeof props.value === 'number');

const link = computed(() => {
  if (!props.value) {
    return null;
  }

  switch (props.type) {
    case 'phone':
      return `tel:${props.value}`;
    case 'email':
      return `mailto:${props.value}`;
    default:
      return null;
  }
});

const valueDisplay = computed((): string => {
  if (props.value === undefined || props.value === null) {
    return '-';
  }

  const valueToString = (value: string | number | boolean | BasePhone) => {
    switch (props.type) {
      case 'currency':
        return useCurrencySymbol(value as number).value;
      case 'phone':
        return phonePrettier(props.value as BasePhone);
    }

    switch (typeof value) {
      case 'boolean':
        return value ? 'GENERAL.YES' : 'GENERAL.NO';
      case 'number':
        return numberWithCommas(value);
      default:
        return value.toString();
    }
  };

  return Array.isArray(props.value) ? props.value.map(valueToString).join(', ') : valueToString(props.value);
});

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (__props.value || !__props.hideEmpty)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['d-flex gap-1', { 'flex-column': !__props.flat }])
      }, [
        (__props.label)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t(__props.label)), 1))
          : _createCommentVNode("", true),
        (link.value && __props.value)
          ? _withDirectives((_openBlock(), _createElementBlock("a", {
              key: 1,
              class: _normalizeClass(['text-body-1 text-auto text-ellipsis', { ltr: __props.ltr }]),
              href: link.value
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t(valueDisplay.value)), 1)
            ], 10, _hoisted_2)), [
              [_directive_tooltip]
            ])
          : (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(['text-body-1 d-flex gap-1 flex-wrap text-auto', { 'text-ellipsis': __props.textEllipsis, ltr: __props.ltr }])
            }, [
              (__props.value && !__props.refer)
                ? _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(['text-ellipsis', { ltr: isNumber.value }])
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t(valueDisplay.value)), 1)
                  ], 2)), [
                    [_directive_tooltip]
                  ])
                : (__props.value && __props.refer)
                  ? (_openBlock(), _createBlock(VzButton, {
                      key: 1,
                      class: "text-ellipsis pa-0",
                      type: "flat",
                      text: valueDisplay.value,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(routeTo)(__props.refer)))
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true),
              (_ctx.$slots['default'])
                ? _renderSlot(_ctx.$slots, "default", { key: 2 })
                : _createCommentVNode("", true)
            ], 2))
      ], 2))
    : _createCommentVNode("", true)
}
}

})