import type { AxiosRequestInterceptor } from '@/shared/services/api-service/models';
import { AxiosError, type InternalAxiosRequestConfig } from 'axios';
import { SESSION } from '@/store/auth/auth.constants';
import { useAuthStore } from '@/store/auth';
import { storeToRefs } from 'pinia';
import authLocalStore from '@/store/auth/helpers/auth-local-store';

const loadSession = () => {
  try {
    const { [SESSION]: authSession } = storeToRefs(useAuthStore());

    return authSession.value;
  } catch (error) {
    const localStore = authLocalStore.load();

    return localStore?.[SESSION];
  }
};

export const addUserDetailsInterceptor: AxiosRequestInterceptor = {
  onFulfilled: (request: InternalAxiosRequestConfig) => {
    const session = loadSession();
    const { skills, specialization, currency, location, timezone, language } = session || {};

    (request.headers || {}).timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (language) {
      (request.headers || {}).lang = language;
    }

    if (skills) {
      (request.headers || {}).skills = skills;
    }

    if (specialization) {
      (request.headers || {}).specialization = specialization;
    }

    if (currency) {
      (request.headers || {}).currency = currency;
    }

    if (location?.coordinates) {
      (request.headers || {}).coordinates = location?.coordinates.join(',');
    }

    return request;
  },
  onRejected: (error: AxiosError) => Promise.reject(error),
};
