import type { ListCompaniesRes } from 'src/views/employee/types';
import type { BaseAddress, BaseOptionsResponse } from '@/shared/models';
import type { GetVideoDetailsRes, GetAttachmentRes, CreatePdfReq, ListCurrenciesRes, ListPhoneCodesRes } from '@/store/client/models';
import {
  CREATE_PDF,
  GET_ATTACHMENT,
  LIST_CURRENCIES,
  GET_VIDEO_DETAILS,
  LIST_ADDRESSES,
  LIST_COMPANIES,
  LIST_PHONE_CODES,
  GET_DATA,
} from '@/store/client/client.constants';
import ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';

const GET_ATTACHMENT_TIMEOUT = 3 * 60 * 1000; // 3 minutes
const CREATE_PDF_TIMEOUT = 3 * 60 * 1000; // 3 minutes

@injectable()
export default class ClientServices {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private localeUrl = '/locale';
  private locationUrl = '/location';
  private employeeUrl = '/employee';
  private mediaUrl = '/media';
  private attachmentUrl = '/attachments';

  public async [LIST_CURRENCIES](code?: string): Promise<ListCurrenciesRes> {
    return this.apiService.get<ListCurrenciesRes>(
      code ? `${this.localeUrl}/${LIST_CURRENCIES}?currency=${code}` : `${this.localeUrl}/${LIST_CURRENCIES}`
    );
  }

  public async [LIST_PHONE_CODES](): Promise<ListPhoneCodesRes> {
    return this.apiService.get<ListPhoneCodesRes>(`${this.localeUrl}/${LIST_PHONE_CODES}`);
  }

  public async [LIST_COMPANIES](query: string): Promise<ListCompaniesRes> {
    return this.apiService.get<ListCompaniesRes>(`${this.employeeUrl}/${LIST_COMPANIES}?search=${query}`);
  }

  public async [LIST_ADDRESSES](query: string): Promise<BaseOptionsResponse<BaseAddress>> {
    return this.apiService.get<BaseOptionsResponse<BaseAddress>>(`${this.locationUrl}/${LIST_ADDRESSES}?search=${query}`, { allowOnceAtTime: true });
  }

  public async [GET_VIDEO_DETAILS](url: string): Promise<GetVideoDetailsRes> {
    return this.apiService.post<GetVideoDetailsRes>(`${this.mediaUrl}/${GET_VIDEO_DETAILS}`, { url });
  }

  public async [GET_ATTACHMENT](id: string, noCache?: boolean): Promise<GetAttachmentRes> {
    return this.apiService.get<GetAttachmentRes>(`${this.attachmentUrl}/${GET_ATTACHMENT}/${id}`, { timeout: GET_ATTACHMENT_TIMEOUT, noCache });
  }

  public async [GET_DATA](hid: string): Promise<Blob> {
    return this.apiService.get<Blob>(`${this.attachmentUrl}/${GET_DATA}/${hid}`, { responseType: 'blob' });
  }

  public async [CREATE_PDF](data: CreatePdfReq): Promise<string> {
    return this.apiService.post<string>(`${this.attachmentUrl}/${CREATE_PDF}`, data, { timeout: CREATE_PDF_TIMEOUT });
  }
}
