import scriptCdn from '@shared/helpers/script-cdn';
import ICONS from './pdf-viewer.svg.json';

class PDFViewer extends HTMLElement {
  constructor() {
    super();
    this.shadow.appendChild(this.head);

    this.body.style.margin = '0';
    this.body.style.display = 'flex';
    this.body.style.flexDirection = 'column';
    this.body.style.height = 'calc(100% - 16px)';
    this.body.style.width = '100%';
    this.body.style.overflow = 'hidden';
    this.body.style.border = '1px solid #ccc';

    this.toolbar.style.display = 'flex';
    this.toolbar.style.justifyContent = 'space-between';
    this.toolbar.style.alignItems = 'center';
    this.toolbar.style.padding = '8px 16px';
    this.toolbar.append(document.createElement('div'), document.createElement('span'));

    this.container.style.flexGrow = '1';
    this.container.style.overflow = 'auto';
    this.container.style.padding = '0 16px';
    this.container.style.textAlign = 'center';

    this.body.appendChild(this.toolbar);
    this.body.appendChild(this.container);
    this.shadow.appendChild(this.body);

    this.initToolbar();
  }

  private shadow: ShadowRoot = this.attachShadow({ mode: 'open' });
  private head: HTMLHeadElement = document.createElement('head');
  private body: HTMLBodyElement = document.createElement('body');
  private container: HTMLElement = document.createElement('div');
  private toolbar: HTMLElement = document.createElement('div');
  private isRendering: boolean = false;
  private iframe: HTMLIFrameElement | null = null;
  private fileName: string = 'download.pdf';
  private src: string | null = null;
  private currentPage: number = 1;
  private totalPages: number = 0;
  private pdfDoc: any = null;
  private scale: number = 2.0;
  private isDragging: boolean = false;
  private dragStartX: number = 0;
  private dragStartY: number = 0;
  private scrollStartX: number = 0;
  private scrollStartY: number = 0;
  private mouseMoveListener: (event: MouseEvent) => void = () => {};
  private mouseUpListener: (event: MouseEvent) => void = () => {};
  private mouseDownListener: (event: MouseEvent) => void = () => {};
  private dragStartListener: (event: DragEvent) => void = () => {};

  private initDragScroll() {
    this.mouseMoveListener = (event) => {
      if (!this.isDragging) return;

      const deltaX = event.clientX - this.dragStartX;
      const deltaY = event.clientY - this.dragStartY;

      this.container.scrollLeft = this.scrollStartX - deltaX;
      this.container.scrollTop = this.scrollStartY - deltaY;
    };

    this.mouseUpListener = () => {
      if (this.isDragging) {
        this.isDragging = false;
        this.container.style.cursor = '';
        this.container.style.userSelect = '';
      }
    };

    this.mouseDownListener = (event) => {
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.scrollStartX = this.container.scrollLeft;
      this.scrollStartY = this.container.scrollTop;
      this.container.style.cursor = 'grabbing';
      this.container.style.userSelect = 'none';
    };

    this.dragStartListener = (event) => event.preventDefault();

    this.container.addEventListener('mousedown', this.mouseDownListener);
    this.container.addEventListener('dragstart', this.dragStartListener);
    document.addEventListener('mousemove', this.mouseMoveListener);
    document.addEventListener('mouseup', this.mouseUpListener);
  }

  private actions: Array<{ name: string; icon?: { path: string; viewBox: string }; onClick: () => void }> = [
    { name: 'zoom-in', onClick: () => this.changeZoom(0.1), icon: ICONS['zoom-in'] },
    { name: 'zoom-out', onClick: () => this.changeZoom(-0.1), icon: ICONS['zoom-out'] },
    { name: 'download', onClick: () => this.downloadPDF(), icon: ICONS['download'] },
    { name: 'print', onClick: () => this.printPDF(), icon: ICONS['print'] },
  ];

  static get observedAttributes() {
    return ['src', 'filename', 'previous-page', 'next-page', 'zoom-in', 'zoom-out', 'download', 'print'];
  }

  private scrollListener: () => void = this.onScroll.bind(this);

  async connectedCallback() {
    await scriptCdn('https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.min.js', { root: this.head });
    await this.renderPDF();

    this.container.addEventListener('scroll', this.scrollListener);
    this.initDragScroll();
  }

  async attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
    if (oldValue === newValue) {
      return;
    }

    switch (name) {
      case 'filename':
        this.fileName = newValue || 'download.pdf';
        break;
      case 'src':
        this.iframe?.remove();
        await this.renderPDF(newValue);
        break;
      default:
        this.actions = this.actions.map((action) => (action.name === name ? { ...action, label: newValue || undefined } : action));
    }
  }

  private disconnectedCallback() {
    this.container.removeEventListener('scroll', this.scrollListener);
    this.container.removeEventListener('mousedown', this.mouseDownListener);
    this.container.removeEventListener('dragstart', this.dragStartListener);
    document.removeEventListener('mousemove', this.mouseMoveListener);
    document.removeEventListener('mouseup', this.mouseUpListener);
  }

  private getComputedDirection(): 'rtl' | 'ltr' {
    const direction = window.getComputedStyle(document.documentElement).direction;

    return direction === 'rtl' ? 'rtl' : 'ltr';
  }

  private async renderPDF(src: string | null = this.src) {
    this.src = src;

    if (!src) {
      return;
    }

    try {
      const pdfjsLib = (window as any).pdfjsLib;

      if (!pdfjsLib) {
        console.error('pdfjsLib is not loaded. Make sure to include the PDF.js CDN.');

        return;
      }

      pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.worker.min.js';

      this.pdfDoc = await pdfjsLib.getDocument(src).promise;
      this.totalPages = this.pdfDoc.numPages;

      this.updateToolbar();
      await this.initScale();
      await this.renderPages();
    } catch (error) {
      console.error('Error rendering PDF:', error);
    } finally {
      this.initToolbar();
    }
  }

  private async renderPage(pageNum: number) {
    const page = await this.pdfDoc.getPage(pageNum);
    const originalViewport = page.getViewport({ scale: this.scale });
    const resolutionFactor = 2; // Increase this for higher-quality rendering
    const virtualCanvas = document.createElement('canvas');
    const virtualContext = virtualCanvas.getContext('2d')!;
    virtualCanvas.width = originalViewport.width * resolutionFactor;
    virtualCanvas.height = originalViewport.height * resolutionFactor;

    await page.render({ canvasContext: virtualContext, viewport: page.getViewport({ scale: this.scale * resolutionFactor }) }).promise;

    const canvas = document.createElement('canvas');
    const content = canvas.getContext('2d')!;
    canvas.width = originalViewport.width;
    canvas.height = originalViewport.height;
    canvas.dataset.pageNumber = pageNum.toString();

    content.drawImage(virtualCanvas, 0, 0, virtualCanvas.width, virtualCanvas.height, 0, 0, canvas.width, canvas.height);

    return canvas;
  }

  private async renderPages() {
    if (this.isRendering) return;
    this.isRendering = true;

    try {
      this.container.replaceChildren(); // Clear existing pages
      for (let i = 1; i <= this.totalPages; i++) {
        const canvas = await this.renderPage(i);
        this.container.appendChild(canvas);
      }
    } finally {
      this.isRendering = false;
    }
  }

  private async initScale() {
    const { width } = this.container.getBoundingClientRect();

    const page = this.pdfDoc.getPage(1);
    page.then((pdfPage: any) => {
      const viewport = pdfPage.getViewport({ scale: 1 });
      this.scale = (width / viewport.width) * 0.9;
      this.renderPages();
    });
  }

  private initToolbar() {
    const isDisabled = !this.src || this.totalPages === 0;
    const controls = document.createElement('div');
    controls.style.display = 'flex';

    const toolbarButtons = this.actions.map(({ name, icon, onClick }) =>
      this.createButton(icon || ICONS[name as keyof typeof ICONS], onClick, isDisabled, this.getAttribute(name) || '')
    );

    controls.append(...toolbarButtons);
    this.toolbar.replaceChild(controls, this.toolbar.firstElementChild!);
  }

  private createButton(
    { path, viewBox, size = 14 }: { path: string; viewBox: string; size?: number },
    onClick: () => void,
    disabled: boolean = false,
    label: string
  ): HTMLButtonElement {
    const button = document.createElement('button');
    button.style.cursor = 'pointer';
    button.style.backgroundColor = 'transparent';
    button.style.border = '1px solid #ccc';
    button.style.borderRadius = '4px';
    button.style.margin = '0 4px';
    button.style.width = '28px';
    button.style.height = '28px';
    button.style.display = 'flex';
    button.style.justifyContent = 'center';
    button.style.alignItems = 'center';
    button.disabled = disabled;
    button.ariaLabel = label || '';
    button.addEventListener('click', onClick);

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('viewBox', viewBox);
    svg.innerHTML = path;
    svg.setAttribute('width', size.toString());
    svg.setAttribute('height', size.toString());
    button.appendChild(svg);

    return button;
  }

  private async changePage(offset: number) {
    const newPage = this.currentPage + offset;

    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;

      this.container.querySelector(`canvas[data-page-number="${this.currentPage}"]`)?.scrollIntoView({ behavior: 'auto', block: 'start' });
      this.updateToolbar();
    }
  }

  private async changeZoom(delta: number) {
    const prevScrollWidth = this.container.scrollWidth;
    this.scale = Math.max(0.5, Math.min(this.scale + delta, 5));

    await this.renderPages();

    const diff = this.container.scrollWidth - prevScrollWidth;
    this.container.scrollTo({ top: diff / 2, left: diff / 2 });
  }

  private onScroll() {
    const canvases = Array.from(this.container.querySelectorAll('canvas'));
    let mostVisiblePage = this.currentPage;
    let maxVisibility = 0;

    canvases.forEach((canvas) => {
      const rect = canvas.getBoundingClientRect();
      const containerRect = this.container.getBoundingClientRect();
      const visibleHeight = Math.max(0, Math.min(rect.bottom, containerRect.bottom) - Math.max(rect.top, containerRect.top));

      if (visibleHeight > maxVisibility) {
        maxVisibility = visibleHeight;
        mostVisiblePage = parseInt(canvas.dataset.pageNumber || '1', 10);
      }
    });

    if (mostVisiblePage !== this.currentPage) {
      this.currentPage = mostVisiblePage;
      this.updateToolbar();
    }
  }

  private downloadPDF() {
    const link = document.createElement('a');

    link.href = this.src!;
    link.download = this.fileName;
    link.click();
  }

  private async printPDF() {
    this.iframe?.remove();

    try {
      const response = await fetch(this.src!);
      const blob = await response.blob();

      const objectURL = URL.createObjectURL(blob);

      this.iframe = document.createElement('iframe');
      this.iframe.style.display = 'none';
      this.iframe.src = objectURL;
      this.iframe.onload = () => {
        this.iframe?.contentWindow?.print();
        URL.revokeObjectURL(objectURL); // Clean up the object URL
      };

      this.body.appendChild(this.iframe);
    } catch (error) {
      console.error('Error fetching or printing the PDF:', error);
    }
  }

  private updateToolbar() {
    const navigation = document.createElement('div');
    navigation.style.display = 'flex';
    navigation.style.alignItems = 'center';
    navigation.style.flexDirection = this.getComputedDirection() === 'rtl' ? 'row-reverse' : 'row';

    const prevButton = this.createButton(
      { ...ICONS['previous-page'], size: 12 },
      () => this.changePage(-1),
      this.currentPage === 1,
      this.getAttribute('previous-page') || ''
    );
    prevButton.style.border = 'none';

    const nextButton = this.createButton(
      { ...ICONS['next-page'], size: 12 },
      () => this.changePage(1),
      this.currentPage === this.totalPages,
      this.getAttribute('next-page') || ''
    );
    nextButton.style.border = 'none';

    const info = document.createElement('span');
    info.textContent = `${this.currentPage} / ${this.totalPages}`;
    info.style.direction = 'ltr';

    navigation.append(prevButton, info, nextButton);
    this.toolbar.replaceChild(navigation, this.toolbar.lastElementChild!);
  }
}

customElements.define('pdf-viewer', PDFViewer);
