<template>
  <div class="vz-spinner" :aria-label="t('COMPONENT_LABELS.LOADING', { value: ariaLabel })" :style="size ? { height: size, width: size } : {}">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { SizeUnit } from '@shared/types';
import { useTranslator } from '@/plugins/i18n/helpers';

defineProps({
  loading: { type: Boolean, default: true },
  ariaLabel: { type: String, default: 'GENERAL.LOADING' },
  size: { type: String as PropType<SizeUnit | undefined>, default: undefined },
});

const t = useTranslator();
</script>

<style lang="scss">
.vz-spinner {
  margin: 0 auto;
  position: relative;
  color: var(--color-mono-300);
  height: 2.25rem !important;
  width: 2.25rem !important;
  aspect-ratio: 1/1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 0.75rem);
    width: calc(100% - 0.75rem);
    border-radius: 50%;
    animation: rotation 1s linear infinite;
    border-style: solid;
    border-top-color: var(--color-primary-900);
    border-bottom-color: currentColor;
    border-width: 0.125rem;
    margin: 0.375rem;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
