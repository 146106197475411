import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = { class: "vz-textarea__container" }
const _hoisted_3 = ["placeholder", "disabled", "readonly", "rows", "aria-label"]
const _hoisted_4 = { key: 1 }

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { computed, nextTick, type PropType, ref } from 'vue';
import { useValidator } from '@/shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';
import { scrollToView } from '@shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-textarea',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: [Object, Number, String, Array] as PropType<any>, required: true },
  label: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  resizable: { type: Boolean, default: false },
  rows: { type: [Number, String], default: 5 },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: String as PropType<string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
  classLabel: { type: String, default: '' },
},
  emits: ['update:model-value', 'input'],
  setup(__props, { emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;

const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const inputRef = ref();

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const vModel = computed({
  get: (): any => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const externalError = computed(() => {
  if (props.fieldErrors && props.name && props.fieldErrors[props.name]) {
    return props.fieldErrors[props.name];
  }

  if (!props.errorMessage) {
    return;
  }

  return props.errorMessage;
});

const onUpdate = (ev: Event): void => {
  const { value } = ev.target as HTMLInputElement;
  emit('update:model-value', value);
};

const onFocus = () => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  isFocus.value = true;
  nextTick(() => scrollToView(inputRef.value, { behavior: 'smooth', block: 'center' }));
};

const onBlur = () => (blurTimeout.value = setTimeout(() => (isFocus.value = false), 500));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "inputRef",
    ref: inputRef,
    class: _normalizeClass(["vz-textarea", { 'vz-textarea--loading': __props.loading, 'vz-textarea--disabled': __props.disabled }]),
    "data-errors": _unref(validateMessage)
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(['text-ellipsis', __props.classLabel])
    }, _toDisplayString(_ctx.$t(__props.label)), 3),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vModel).value = $event)),
        placeholder: _ctx.$t(__props.placeholder),
        disabled: __props.disabled,
        readonly: __props.readonly,
        rows: __props.rows,
        style: _normalizeStyle({ resize: !__props.resizable ? 'none' : 'vertical' }),
        "aria-label": _unref(t)('COMPONENT_LABELS.TEXTAREA', { value: __props.ariaLabel || __props.label || __props.placeholder }),
        onInput: onUpdate,
        onFocus: onFocus,
        onBlur: onBlur
      }, null, 44, _hoisted_3), [
        [_vModelText, vModel.value]
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['vz-textarea__error', { 'vz-textarea__error--hidden': __props.hideDetails }]),
      role: "alert"
    }, [
      (_unref(validateMessage))
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-textarea__error-internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
        : (externalError.value && !_unref(isTouched))
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t(externalError.value)), 1))
          : _createCommentVNode("", true)
    ], 2)
  ], 10, _hoisted_1))
}
}

})